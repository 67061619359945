import React, { useState } from 'react';
import { faComment, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { eliminarTemaForo } from '../../../actions/cursosActions';
import EditForoModal from '../ForoDocente/EditForoModal'
import NuevoTema from '../TemaForo/NuevoTema';
import { updateStateModalBasic } from "../../../actions/modalActions";

const Tema = ({ tema,idProyecto}) => {
  const usuario = useSelector(state => state.usuario.usuario);
  const dispatch = useDispatch()

    // Estado para el modal de edición
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [temaActual, setTemaActual] = useState(null);

  const eliminarTema = (id) => {
    dispatch(eliminarTemaForo(id))
  }

  const actualizarTema = (tema) => {
    setTemaActual(tema);
    setEditModalOpen(true);
  };

  const cerrarModal = () => {
    setEditModalOpen(false);
    setTemaActual(null);
  };


  return (
    <div className="row" style={{ padding: '15px', marginBottom: '10px', borderBottom: '1px solid #EEEEED' }}>
      <div className="col-md-2">
        {
          tema.img_usuario === null ? <img

            alt=""
            src='/images/user.png'
            style={{ width: '70px', height: '70px', borderRadius: '100%' }}
          /> :
            <img
              alt=""
              src={`${process.env.REACT_APP_API_URL}/${tema.img_usuario}`}
              style={{ width: '70px', height: '70px', borderRadius: '100%' }}
            />

        }
      </div>
      <div className="col-md-6">
        <h4><Link to={'foro/tema/' + tema.slug} style={{ color: '#1E2639' }}>{tema.titulo_tema}</Link></h4>
        <p>Por {tema.nombre_usuario + ' ' + tema.apellido_usuario}</p>
        <p><span style={{ marginRight: '5px' }}><FontAwesomeIcon icon={faComment} /></span>{tema.cantidad_comentarios}</p>
        {tema.autor === usuario.id_usuario ?
          <>
            <button className="btn btn-danger float-right ml-2" onClick={() => eliminarTema({ id_tema: tema.id_tema, proyecto: tema.id_proyecto })}><FontAwesomeIcon icon={faTrash} /></button>
            <button className="btn btn-warning float-right" onClick={() => actualizarTema(tema)}><FontAwesomeIcon icon={faEdit} /></button>

          </>
          : ''
        }

      </div>
      <div className="col-md-4">
        <p>Publicado el: {tema.fecha_registro}</p>
        <br />
        {tema.ultimo_comentario ? <p><strong>último comentario por </strong>{tema.ultimo_comentario.nombre_usuario}</p> : ''}
      </div>

      {/* Modal para editar el tema */}
      {isEditModalOpen && (
        <EditForoModal
          isOpen={isEditModalOpen}
          onClose={cerrarModal}
          tema={temaActual}
          idProyecto={idProyecto}
        />
      )}
    </div>
  );
};

export default Tema;