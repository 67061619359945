import React, { useState, useEffect } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import SimpleSelect from "../../components/plugins/SimpleSelect";

//Redux
import { connect, useDispatch } from "react-redux";
import {
  traerProyectosDocente, traerTemas,
} from "../../../actions/cursosActions";

import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import Foro from "./Foro";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import NuevoTema from "../TemaForo/NuevoTema";

const ForoDocente = (props) => {
  //State props
  const { usuario, temasForo } = props;
  //Funciones
  const {
    traerProyectosDocente,
  } = props;

  const [selectProyecto, setSelecProyecto] = useState([]);
  const [idProyecto, setIdProyecto] = useState(null);
  // const [temasForo,setTemasForo] = useState(null)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const llenarSelectProyectos = async () => {
    let dataGenerado = [];

    const proyectosDocente = await traerProyectosDocente(usuario.id_empleado)

    proyectosDocente.map((proyecto) => {
      dataGenerado = [
        ...dataGenerado,
        {
          value: proyecto.id_proyecto,
          label: proyecto.nombre_proyecto,
        },
      ];
    });
    setSelecProyecto(dataGenerado);
  };

  useEffect(() => {
    llenarSelectProyectos();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const temas = await dispatch(traerTemas(idProyecto.value))
      //setTemasForo(temas);
      setLoading(false)
    }
    if (idProyecto) {
      setLoading(true)
      fetchData()
    }
  }, [idProyecto,dispatch])

  return (
    <Layout>
      <HeaderContent title="Foro" icon="create_new_folder" />
      <Breadcrumb
        link={[
          { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
          { url: "/foro-producto", nombre: "Foro" },
        ]}
      />

      <div className="container-fluid px-0 recursos">
        <div className="row">
          <div className="col-md-9">
            <div className="row recursos__content-filtro">
              <div className="col-md-4 filtro">
                <label htmlFor="">Producto</label>
                <SimpleSelect
                  options={selectProyecto}
                  getSelection={setIdProyecto}
                />
              </div>
            </div>
          </div>
          <div className="col-md-3 recursos__content-nuevo">
            <NuevoTema idProyecto={idProyecto ? idProyecto.value : null} />
          </div>
        </div>

        <div className="box content-recursos-tab">
          <div className="box-body">
            <SpinnerLoading loading={loading} >
              <Foro temas={temasForo} idProyecto={idProyecto} />
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  usuario: state.usuario.usuario,
  temasForo: state.foro.temas,
});

const mapDispatchToProps = {
  traerProyectosDocente,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForoDocente);
