import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../../app/Layout';
import HeaderContent from '../General/HeaderContent';
import Breadcrumb from '../General/Breadcrumb';
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListAlt } from "@fortawesome/free-solid-svg-icons";
import MaterialtableDemo from "../../components/plugins/MaterialTableDemo";
import { actualizarEstadoRuleta, listarPremiosRuleta } from '../../../actions/premiosActions';


const Premios = () => {
    const dispatch = useDispatch();
    const { premios, loading } = useSelector(state => state.premios);
    const [localPremios, setLocalPremios] = useState([]);

    useEffect(() => {
        dispatch(listarPremiosRuleta());
    }, []);

    useEffect(() => {
        setLocalPremios(premios);
    }, [premios]);

    const handleActualizarEstado = async (id, caso) => {
        await dispatch(actualizarEstadoRuleta(id, caso));
        setLocalPremios(prevState => 
            prevState.map(premio => 
                premio.id_ruleta === id ? { ...premio, estado: caso === 1 ? 'reclamado' : 'pendiente' } : premio
            )
        );
    }

    

    return (
        <Layout>
            <div className='Premios'>
                <div className="Premios__head">
                    <HeaderContent title="Premios" icon="group" />
                    <Breadcrumb
                        link={[
                            { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                            { url: "", nombre: "Área de Trabajo" },
                            { url: "", nombre: "Premios" },
                        ]}
                    />
                </div>
                <div className='Premios__container'>
                    <div className="Premios__body container-fluid  px-0 content-actions mt-3">
                        <SpinnerLoading loading={loading}>
                            <div className="box mt-5">
                                <div className="box-header with-border">
                                    <h5
                                        style={{ color: "#434343" }}
                                    >
                                        <FontAwesomeIcon icon={faListAlt} /> Lista de contratos
                                    </h5>
                                    {/*
                                    <div className="mt-3">
                                        <button className="btn btn-primary" onClick={handleShowModal}>Columnas</button>
                                    </div>*/}
                                </div>
                                <div className="box-primary">
                                    <MaterialtableDemo
                                        columns={colums(handleActualizarEstado)}// Pasar las columnas filtradas
                                        tabla={localPremios}
                                        keyCol="id_contrato"
                                    />
                                </div>
                            </div>
                        </SpinnerLoading>
                    </div>
                </div>
            </div>

        </Layout>
    );
};

export default Premios;

/**====================== TABLA =========== */

const colums = (handleActualizarEstado) => [
    {
        text: 'ID',
        key: 'id_ruleta',
        className: 'id_ruleta',
        width: 50,
        sortable: true
    },
    {
        text: 'Matricula',
        key: 'id_matricula',
        className: 'id_matricula',
        sortable: true
    },
    {
        text: 'Premio',
        key: 'premio',
        className: 'premio',
        width: 250,
        sortable: true
    },
    {
        text: 'Tipo de Premio',
        key: 'tipo_premio',
        className: 'tipo_premio',
        width: 100,
        sortable: true,
        cell: (record) => (
            <span>{record.tipo_premio == 1 ? 'Descuento' : 'Curso gratis'}</span>
        )
    },
    {
        text: 'DNI',
        key: 'dni',
        className: 'dni',
        width: 70,
        sortable: true,
    },
    {
        text: 'Estudiante',
        key: 'nombre_estudiante',
        className: 'nombre_estudiante',
        width: 250,
        sortable: true,
        cell: (record) => (
            <span>{record.nombre_estudiante + ' ' + record.apellido_estudiante}</span>
        )
    },
    {
        text: 'Codigo',
        key: 'codigo',
        className: 'codigo',
        width: 50,
        sortable: true,
        cell: (record) => (
            <span className="badge bg-primary text-light shadow-sm p-2 rounded"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Copia este código para usarlo">
                {record.codigo}
            </span>
        )
    },
    {
        text: 'Opcion',
        key: 'opcion',
        className: 'opcion',
        width: 250,
        sortable: true,
        cell: (record) => (
            <span>{record.id_proyecto ? record.nombre_proyecto : record.nombre_sesion}</span>
        )
    },
    {
        text: 'Estado',
        key: 'estado',
        className: 'estado',
        width: 50,
        sortable: true
    },
    {
        text: 'Acciones',
        key: 'accion',
        className: 'accion',
        width: 50,
        sortable: true,
        cell: (record) => (
            <div className="d-flex justify-content-center">
                {record.estado === 'pendiente' ?
                    <button className="btn btn-success btn-sm me-1" onClick={()=>handleActualizarEstado(record.id_ruleta,1)}>Aprobar</button>
                    : <button className="btn btn-warning btn-sm me-1" onClick={()=>handleActualizarEstado(record.id_ruleta,2)}>Anular</button>}
            </div>
        )
    },

]