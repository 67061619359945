import React from 'react';
import FormularioFiltroGrupos from '../../pages/GruposAcademicos/FormularioFiltroGrupos';

const FormularioFiltroComentarios = ({ onFilter }) => {
  return (
    <div className="mb-3">
      <FormularioFiltroGrupos />
    </div>
  );
};

export default FormularioFiltroComentarios;