import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import './ContenidoPlan.scss';
import ConceptoItemResumen from './ConceptoItemResumen';
import * as moment from 'moment';

const ContenidoPlan = ({ plan = {}, setConceptosFinales, conceptosFinales, handleEditarConcepto,fecha_inicio_p,discente_trabajador }) => {
  const { dt_plan: conceptos = [], plan: planes = [], id_tp_modalidad} = plan;
  const [modalidad,setModalidad] = useState(null);
  const num_romanos =["I","II","III","IV","V","VI","VII","VIII"];
  const mostarItems = () => {
    if(planes.length>1 && !discente_trabajador) return <Alert variant='danger'>
      El discente tiene mas de un plan asignado para este producto
    </Alert>
    else if(planes.length==0 && !discente_trabajador ) return <Alert variant='danger'>
      El discente no tiene planes asignados para este producto
      </Alert>
    else if(!conceptos.length) {
      return <Alert variant='danger'>
        No hay contenido en este plan
      </Alert>
    } else { 
      return (
        <>
          {planes.length == 1 && (
            <Alert variant='info'>
              {planes[0].nombre_plan}
            </Alert>
          )}
          {[...conceptosFinales].map((concepto, index) => (
            <ConceptoItemResumen
              key={index}
              handleEditarConcepto={handleEditarConcepto}
              idModalidad={modalidad}
              concepto={concepto}
            />
          ))}
        </>
      );
    }
  }

  const actualizarFechas = () => {
    let copiaConceptos = [...conceptos];
    let mes_adicional = {
      monto: 0,
      nombre_concepto: `MENSUALIDAD ${num_romanos[conceptos.length]}` ,
      fecha_limite:null,
      id_tp_concepto: 2,
      orden: conceptos.length+1
    }
    if(modalidad !==2) { //Si es Mensualidad

      let fechaActual = moment(fecha_inicio_p);
      let dia = 0;
      let fecha_inicio = moment(fecha_inicio_p);
      let fechaStandar = moment(fechaActual).add(dia,'day');
      ////console.log(fechaActual,fecha_inicio)
      
      copiaConceptos.forEach((concepto,i) => {
      /*//console.log('mismo mes',fechaActual.format("M")===fecha_inicio.format("M"))
       //console.log('mismi año',fechaActual.format("YYYY")===fecha_inicio.format("YYYY"))*/
        if(modalidad==1 && fechaActual.format("YYYY")===fecha_inicio.format("YYYY"))
        {
          ////console.log('mismo mes',fechaActual.format("M")===fecha_inicio.format("M"))
         // //console.log('mismi año',fechaActual.format("YYYY")===fecha_inicio.format("YYYY"))
          if(i===1)
          { 
            mes_adicional.monto=0;
            let fecha_second = new Date(`${fechaActual.format("YYYY")}-${fechaActual.format("MM")}-30 00:00:00`)
            let daysDiff = moment(fecha_second).diff(moment(fechaActual),'days')
            //console.log(concepto.monto)
            let fraccion = parseFloat(parseInt(concepto.monto)/30).toFixed(0) ;
            let monto_f = parseFloat(fraccion*daysDiff).toFixed(2);
            mes_adicional.monto= concepto.monto-monto_f
            concepto.fecha_limite = moment(fecha_second).format('YYYY-MM-DD');
           // concepto.monto=concepto.monto - mes_adicional.monto;
            concepto.monto_aux=monto_f;
          }
          else
          {    
            concepto.fecha_limite = moment(fechaStandar).format('YYYY-MM-DD');
            fechaStandar = moment(fechaStandar).add(1, 'month');
            if(parseInt(fechaStandar.format("M")) !== 2) {
              fechaStandar = `${fechaStandar.format('YYYY')}-${fechaStandar.format('MM')}-30 00:00:00`;
            } 
          }
          

        }
        else
        {    
          concepto.fecha_limite = moment(fechaStandar).format('YYYY-MM-DD');
          fechaStandar = moment(fechaStandar).add(1, 'month');
          if(parseInt(fechaStandar.format("M")) !== 2) {
            fechaStandar = `${fechaStandar.format('YYYY')}-${fechaStandar.format('MM')}-30 00:00:00`;
          } 
        }
       
      });

      if(mes_adicional.monto)
      {
        ////console.log(mes_adicional)
        mes_adicional.fecha_limite = moment(new Date(fechaStandar)).format('YYYY-MM-DD');
        copiaConceptos = [...copiaConceptos, mes_adicional]
      }

    }
    setConceptosFinales(copiaConceptos);
  }

  useEffect(() => {
    if (planes.length > 0 && !discente_trabajador) {
      setModalidad(planes[0].id_tp_modalidad);
    }else{
      setModalidad(id_tp_modalidad);
    }
  }, [plan]);
  
  useEffect(() => {
    if (modalidad !== null) {
      actualizarFechas();
    }
  }, [modalidad]);
  

  return ( 
    <div className="ContenidoPlan mt-3">
      {mostarItems()}
    </div>
  );
}
 
export default ContenidoPlan;