import React, { useState, useEffect } from "react";

import { Form, Button, Card,FormCheck } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { avisoCorrecto } from "../../components/AvisosMensajes/MensajesSwalf";
import AgregarPreguntas from "./agregarPreguntas"
const NuevoExamen = (props) => {
  const [ilimitado, setIlimitado] = useState(false);
  const {examen, guardarExamen, sesion_p, guardarPregunta, eliminarPregunta } = props;
  
  const initialSatate = () => ({
    id_examen:"",
    nombre_examen: "",
    descripcion_examen: "",
    duracion:0,
    sesion: sesion_p.id_sesion,
    estado: "1",
    max_intentos: 0,
  });
  
  const [infoExamen, setinfoExamen] = useState(initialSatate());
  const {id_examen, nombre_examen, descripcion_examen, duracion, sesion, estado, max_intentos } = infoExamen;

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setinfoExamen({
      ...infoExamen,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheck = (e) => {
    const isChecked = e.target.checked;
    setIlimitado(isChecked);
    setinfoExamen({
      ...infoExamen,
      max_intentos: isChecked ? 0 : 1,
    });
  }

  const onSubmit = async (e) => {

    e.preventDefault();
    console.log(infoExamen)

  let respuesta =await dispatch(guardarExamen(infoExamen));
  setinfoExamen({
    ...infoExamen,
    id_examen: respuesta.id_examen,
  });
   if(respuesta.mensaje);
   avisoCorrecto('Examen guardado con éxito');
  };

  useEffect(() => {
    if(examen)
    {
     // console.log(examen)
      setinfoExamen(examen)
    }
  }, [examen])

  return (
      <>
    <div className="col-md-6 nuevo-examen">
    <Card>
      <Form onSubmit={onSubmit}>
        <Card.Body>
          <Form.Group>
          <Form.Control
              type="hidden"
              placeholder="Nuevo titulo"
              name="id_examen"
              value={id_examen}
            />
            <Form.Label>Titulo</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Nuevo titulo"
              name="nombre_examen"
              onChange={handleChange}
              value={nombre_examen}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Descripcion</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              name="descripcion_examen"
              value={descripcion_examen}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Duración</Form.Label>
            <Form.Control
              required
              type="number"
              min="1"
              max="60"
              placeholder="duracion en minutos"
              name="duracion"
              onChange={handleChange}
              value={duracion}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Intentos</Form.Label>
            <Form.Control
              required
              type="number"
              min="1"
              max="10"
              placeholder="cantidad de intentos"
              name="max_intentos"
              onChange={handleChange}
              value={max_intentos}
              readOnly={ilimitado}
            />
          </Form.Group>
          <Form.Group>
          <FormCheck
              type="checkbox"
              label="Ilimitados"
              name="ilimitado"
              checked={ilimitado}
              onChange={handleCheck}
          />
          </Form.Group>
          
        </Card.Body>
        <Card.Footer>
          <Button type="submit" variant='info'>Guardar</Button>
          {infoExamen.id_examen!=="" ? <Link to={'examenes/vistaPrevia/'+infoExamen.id_examen} className="btn btn-success">Vista Previa</Link> : ''}
        </Card.Footer>
      </Form>
      </Card>
    </div>
    { infoExamen.id_examen!=="" ? <AgregarPreguntas idExamen={infoExamen.id_examen} guardarPregunta={guardarPregunta} eliminarPregunta={eliminarPregunta}/> : ''}
    </>
  );
};

export default NuevoExamen;

