import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faListAlt} from "@fortawesome/free-solid-svg-icons";
import MaterialtableDemo from "../../components/plugins/MaterialTableDemo";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import { updateStateModalBasic } from "../../../actions/modalActions";
import ModalBasicwithRedux from "../../../containers/ModalBasicwithRedux";
import EditPlanModal from "./EditPlanModal.js";
import { Badge, Modal, Button, Form } from "react-bootstrap";
import { listarProyectos } from "../../../actions/cursosActions.js";


import "./Trabajadores.scss";

//Reduxt
import {useDispatch,useSelector } from "react-redux";
import { obtenerContratos } from "../../../actions/comercialActions";
import Voucher from "./Voucher";

 
const Trabajadores = () => {

  const [columnVisibility, setColumnVisibility] = useState({});

  const usuario = useSelector((state) => state.usuario);
  const [showModal, setShowModal] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);


  //State
  const dispatch = useDispatch();
  

  const[loading, setLoading] = useState(true)
  const[contratos, setContratos] = useState(null);  
  

  useEffect(() => {

    const fetchData = async () =>{
      const respuesta = await (dispatch(obtenerContratos(usuario.usuario)))
      setContratos(respuesta.contratos)
      setLoading(false);
    }

    fetchData();
    setShouldRefetch(false);


    //aqui se actualiza el estado columnVisibility 
    const savedVisibility = localStorage.getItem('columnVisibility');
    if (savedVisibility) {
      setColumnVisibility(JSON.parse(savedVisibility));
    }


  }, [shouldRefetch]);

     // Modal para asignar el plan
     const handleEditPlanModal = (id_proyecto,id_contrato, planActual, modalidad) => {
      dispatch(updateStateModalBasic(
        <EditPlanModal
          id_proyecto={id_proyecto} 
          id_contrato= {id_contrato}
          planActual= {planActual}
          onSave={handleSavePlan}
          modalidad={modalidad} 
        />,
        'Editar Plan'
      ));
    };

    // Función para actualizar el plan
    const handleSavePlan = (datos) => {

      setShouldRefetch(true);

      setContratos((prevContratos) =>
        prevContratos.map((contrato) =>
          contrato.id_contrato === datos.id_contrato
            ? { ...contrato, id_plan: datos.id_plan } // Actualizar el plan del contrato
            : contrato
        )
      );
    };
    
  const handleModalNuevo = (ruta) => {
    dispatch(updateStateModalBasic(<Voucher rutaImagen={ruta} />, 'Voucher'));
  }

  const toggleColumn = (columnName) => {
    const updatedVisibility = { 
      ...columnVisibility, 
      [columnName]: !columnVisibility[columnName] };
    setColumnVisibility(updatedVisibility);
    localStorage.setItem('columnVisibility', JSON.stringify(updatedVisibility));
  };

  // Función para filtrar las columnas según la visibilidad
  const visibleColumns = colums(handleModalNuevo, handleEditPlanModal,usuario).filter(
    column => !columnVisibility[column.key]
  );

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);


  
  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Contratos" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "", nombre: "Área de Trabajo" },
          { url: "", nombre: "Contratos" },
              ]}
            />
          </div>
          <div className="Trabajadores__body container-fluid  px-0 content-actions mt-3">
          <ModalBasicwithRedux />
            <SpinnerLoading loading={loading}>
              <div className="box mt-5">
                <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faListAlt} /> Lista de contratos
                  </h5>
                  <div className="mt-3">
                    <button className="btn btn-primary" onClick={handleShowModal}>Columnas</button>
                  </div>
                </div>
                <div className="box-primary">
                  <MaterialtableDemo
                    columns={visibleColumns} // Pasar las columnas filtradas
                    tabla={contratos}
                    keyCol="id_contrato"
                  />
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
       {/* Modal para la visibilidad de columnas */}
      <Modal show={showModal} onHide={handleCloseModal} className="modal-columnas">
        <Modal.Header closeButton>
          <Modal.Title>Configuración de Columnas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {colums(handleModalNuevo, handleEditPlanModal).map((column) => (
              <Form.Check
                key={column.key}
                type="switch"
                id={column.key}
                label={column.text}
                checked={!columnVisibility[column.key]} // Si no está oculta, el switch está activado
                onChange={() => toggleColumn(column.key)} // Alternar visibilidad de la columna
                className="mb-2"
              />
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default Trabajadores;

/**====================== TABLA =========== */

const colums = (handleModalNuevo,handleEditPlanModal,usuario) => [
  {
    text: 'ID',
    key: 'id_contrato',
    className: 'id_contrato',
    width: 50,
    sortable: true

  },
  {
    text: "Fecha Registro",
    key: "fecha_registro",
    align: "center",
    className: "fecha_registro",
    sortable: true
  },
  {
    text: "Producto",
    key: "nombre_proyecto",
    align: "center",
    sortable: true,
    className: "nombre_proyecto",
    cell:(record) =>
      <span>
          <strong>{record.id_proyecto}</strong> - {record.nombre_proyecto}
      </span>
    
  },
  {
    text: "Plan",
    key: "id_plan",
    align: "center",
    className: "id_plan",
    sortable: true,
    cell: (record) => (
      (record.asesor == usuario.usuario.id_usuario||usuario.usuario.id_tp_nivel_usuario==13||usuario.usuario.id_tp_nivel_usuario==1) ?
      (
        usuario && usuario.usuario && record.id_plan === null || record.id_plan === "" ? (
          //Comprobar si tiene matricula
          record.tiene_matricula == 'No' ?(
            <button className="btn btn-success" onClick={() => handleEditPlanModal(record.id_proyecto, record.id_contrato, record.id_plan, record.inversion)}>
              Asignar Plan
            </button>
          ):(
          <span>{`Discente ya matriculado`}</span>
          )
          
        ) : (
          <span>{`Plan ${record.nombre_plan}`}</span>
        )
      ) : (
        record.id_plan === null || record.id_plan === "" ? (
          //Comprobar si tiene matricula
          record.tiene_matricula == 'No' ?(
            <span>{`Plan no asignado`}</span>
          ):(
          <span>{`Discente ya matriculado`}</span>
          )

        ) : (
          <span>{`Plan ${record.nombre_plan}`}</span>
        )
      )
      
    )
  },  
  {
    text: "Asesor",
    key: "nombre_usuario",
    align: "center",
    sortable: true,
    className: "nombre_usuario",
  },
  {
    text: "DNI",
    key: "dni",
    align: "center",
    sortable: true,
    className: "dni",
    width: 50
  },
  {
    text: "Nombres",
    key: "nombres",
    align: "center",
    className:"nombres",
    sortable: true,
  },
  {
    text: "Apellidos",
    key: "apellidos",
    align: "center",
    className: "apellidos",
    sortable: true,
  },
  {
    text: "Celular",
    key: "celular",
    align: "center",
    className:"celular",
    sortable: true,
  },
  {
    text: "Correo",
    key: "correo",
    align: "center",
    className: "correo",
    sortable: true,

  },
  {
    text: "Inversión",
    key: "inversion",
    align: "center",
    className: "inversion",
    sortable: true,

  }
  ,
  {
    text: "Tipo de Compra",
    key: "tipo_compra",
    align: "center",
    className: "tipo_compra",
    sortable: true,

  }
  ,
  {
    text: "Fecha inicio",
    key: "fecha_inicio_membresia",
    align: "center",
    className: "fecha_inicio_membresia",
    sortable: true,

  }
  ,
  {
    text: "Fecha Fin",
    key: "fecha_fin_membresia",
    align: "center",
    className: "fecha_fin_membresia",
    sortable: true,

  },
  {
    text: "N° Operación",
    key: "n_operacion",
    align: "center",
    className: "n_operacion",
    sortable: true
  }
  ,
  {
    text: "Comprobante",
    key: "tipo_comprobante",
    align: "center",
    className: "tipo_comprobante",
    sortable: true,
    cell: (record) => <> {record.tipo_comprobante!=="0" ? record.tipo_comprobante==="1"? 'Boleta' : 'Factura': 'No determina'} </>
  }
  ,
  {
    text: "Ruc",
    key: "ruc",
    align: "center",
    className: "ruc",
    sortable: true
  }
  ,
  {
    text: "Voucher",
    key: "voucher",
    align: "center",
    className: "voucher",
    sortable: false,
    cell: (record) => <>
    { record.voucher.includes('pdf')? <div className="d-flex justify-content-center w-100"> <a className="btn btn-danger" target="_blank" href={process.env.REACT_APP_API_URL+'/vouchers/'+record.voucher}><i className="fa fa-file-pdf-o"></i></a></div> : <img style={{maxWidth:"150px"}} src={process.env.REACT_APP_API_URL+'/vouchers/'+record.voucher} alt="imagen" onClick={()=>handleModalNuevo(process.env.REACT_APP_API_URL+'/vouchers/'+record.voucher)} />}
  </>
  }
  ,
 
];
