import authAxios from '../config/authAxios';
import { finanzasReportesTypes, reporteNaranjaTypes } from './types'
import response from '../config/response';

export const getFechaNormalizada = (fechaParam) => {
  const fecha = new Date(fechaParam);
  let fecahNormalizada = `${fecha.getFullYear()}-${fecha.getMonth()+1}-${fecha.getDate()}`;
  return fecahNormalizada;
}

export const obtenerReporteIngreso = datos => {
  return async dispatch => {
    dispatch({
      type: finanzasReportesTypes.OBTENER_REPORTE_INGRESOS_FINANZAS
    })
    const { idProyecto, fechaInicio, fechaFin, idArea } = datos; 
    const fechaInicioConvertido = getFechaNormalizada(fechaInicio)
    const fechaFinConvertido = getFechaNormalizada(fechaFin);
    try {
      const respuesta = await authAxios.get(`/admin/reporte/ingresos/${idProyecto}/${fechaInicioConvertido}/${fechaFinConvertido}/${idArea}`)
      console.log(respuesta.data)
      dispatch({
        type: finanzasReportesTypes.OBTENER_REPORTE_INGRESOS_FINANZAS_EXITO,
        payload: respuesta.data
      })
    
    } catch (error) {
      response.error(error, dispatch, finanzasReportesTypes.OBTENER_REPORTE_INGRESOS_FINANZAS_ERROR)
    }
  }
}

export const obtenerIndicadoresProyectos = (proyectos) => async dispatch => {
  try {
    let respuesta = await authAxios.post(`/admin/obtenerIndicadorFinanciero`,proyectos)
   // console.log(respuesta.data)
    if (!respuesta.data.error) 
    {
      return {mensaje: true, proyectos: respuesta.data}
    }
    else
      {   
        return {mensaje: false, proyectos: null}
      }
    
  } catch (error) {
    console.log(error)
    return {mensaje: false, textMensaje: error}
  }
}
  

export const obtenerReporteNaranja = (datos) => {
  return async dispatch => {
    dispatch({
      type: reporteNaranjaTypes.OBTENER_REPORTE_NARANJA
    })
    const { proyectoSeleccionado, fechaInicio, fechaFin} = datos;
    const fechaInicioConvertido = getFechaNormalizada(fechaInicio)
    const fechaFinConvertido = getFechaNormalizada(fechaFin);
    const data_form = {
        "proyectos_seleccionados": proyectoSeleccionado,
        "fecha_inicio": fechaInicioConvertido,
        "fecha_fin" : fechaFinConvertido
    }
    
    try {

      const respuesta = await authAxios.post(`/admin/reporte/naranja`,data_form)

      dispatch({
        type: reporteNaranjaTypes.OBTENER_REPORTE_NARANJA_EXITO,
        payload: respuesta.data
      })
    
    } catch (error) {
      response.error(error, dispatch, reporteNaranjaTypes.OBTENER_REPORTE_NARANJA_ERROR)
    }
  }
}

export const obtenerReporteRecurrente = (datos) => {
  return async dispatch => {
    dispatch({
      type: reporteNaranjaTypes.OBTENER_REPORTE_RECURRENTE
    })

    try {

      const respuesta = await authAxios.post(`/admin/reporte/afiliacion`,datos)
    //  console.log(respuesta.data)
      dispatch({
        type: reporteNaranjaTypes.OBTENER_REPORTE_RECURRENTE_EXITO,
        payload: respuesta.data
      })
    
    } catch (error) {
      response.error(error, dispatch, reporteNaranjaTypes.OBTENER_REPORTE_RECURRENTE_ERROR)
    }
  }
}

export const limpiarResultadoBusqueda = () => {
  return dispatch => {
    dispatch({
      type: finanzasReportesTypes.LIMPIAR_RESULTADO_BUSQUEDA
    })
  }
}

export const obtenerReporteDeudores = datos => {
  return async dispatch => {
    dispatch({
      type: finanzasReportesTypes.OBTENER_REPORTE_DEUDAS_FINANZAS
    })
    try {
      const { idProyecto, fechaInicio, fechaFin } = datos; 
      const fechaInicioConvertido = getFechaNormalizada(fechaInicio)
      const fechaFinConvertido = getFechaNormalizada(fechaFin);
      const respuesta = await authAxios.get(`/admin/reporte/deudas/${idProyecto}/${fechaInicioConvertido}/${fechaFinConvertido}`);
      dispatch({
        type: finanzasReportesTypes.OBTENER_REPORTE_DEUDAS_FINANZAS_EXITO,
        payload: respuesta.data
      })
    } catch (error) {
      response.error(error, dispatch, finanzasReportesTypes.OBTENER_REPORTE_DEUDAS_FINANZAS_ERROR)
    }
  }
}

export const obtenerFacturas = datos => {
  return async dispatch => {
    dispatch({
      type: finanzasReportesTypes.OBTENER_FACTURAS
    })
    try {
      const { idProyecto, fechaInicio, fechaFin } = datos; 
      const fechaInicioConvertido = getFechaNormalizada(fechaInicio);
      const fechaFinConvertido = getFechaNormalizada(fechaFin);

      const respuesta = await authAxios.get(`/admin/reporte/facturas/${idProyecto}/${fechaInicioConvertido}/${fechaFinConvertido} `);

      dispatch({
        type: finanzasReportesTypes.OBTENER_FACTURAS_EXITO,
        payload: respuesta.data.data
      })
    } catch (error) {
      response.error(error, dispatch, finanzasReportesTypes.OBTENER_FACTURAS_ERROR)
    }
  }
}

export const obtenerDatosFactura = idIngreso => {
  return async dispatch => {
    dispatch({
      type: finanzasReportesTypes.OBTENER_DATOS_FACTURA
    })
    try {
      const respuesta = await authAxios.get(`/admin/matricula/registros/${idIngreso}`);

      dispatch({
        type: finanzasReportesTypes.OBTENER_DATOS_FACTURA_EXITO,
        payload: respuesta.data
      })

    } catch (error) {
      response.error(error, dispatch, finanzasReportesTypes.OBTENER_DATOS_FACTURA_ERROR)
    }
  }
}

export const obtenerReporteCargosDescuentos = datos => {
  return async dispatch => {
    dispatch({
      type: finanzasReportesTypes.OBTENER_REPORTE_CARGOS_DESCUENTOS
    })
    try {
      const { idProyecto, criterioBusqueda } = datos;
      const respuesta = await authAxios.get(`/admin/reporte/cargos?idEvento=${idProyecto}&q=${criterioBusqueda}`);

      if(criterioBusqueda === 'descuento') {
        dispatch({
          type: finanzasReportesTypes.OBTENER_REPORTE_DESCUENTOS_EXITO,
          payload: respuesta.data.data
        });
      } else {
        dispatch({
          type: finanzasReportesTypes.OBTENER_REPORTE_CARGOS_EXITO,
          payload: respuesta.data.data
        });
      }
    } catch (error) {
      response.error(error, dispatch, finanzasReportesTypes.OBTENER_REPORTE_CARGOS_DESCUENTOS_ERROR)
    }
  }
}

export const exportarZohoLead = (datos = []) => {
  let leads = new Array();
  return new Promise(async (resolve, reject) => {
    try {
      if(!datos.length) {
        reject({
          exito: false,
          message: 'No se encontraron datos para exportar.'
        });
      }
  
      leads = datos.map(item => ({
        Full_Name: item.nombre_discente ? item.nombre_discente : '',
        Last_Name: item.nombre_discente ? item.nombre_discente : '',
        Monto_a_Pagar: item.monto_deuda ? parseFloat(item.monto_deuda) : 0,
        Mobile: item.celular ? item.celular : '',
        Email: item.email ? item.email : '',
        Descripci_n_de_deuda: item.concepto ? item.concepto : '',
        Description: item.nombre_proyecto ? item.nombre_proyecto : ''
      }));
    
      const datosEnviar = {
        data: leads
      }

      await authAxios.post('/servicio/zoho/leads', datosEnviar);
      resolve({
        exito: true,
        message: 'Se exporto correctamente.'
      });
    } catch (error) {
      console.log(error);
      reject({
        exito: false,
        message: 'Ocurrió un error inesperado al exportar.'
      });
    }
  });
}
