import React from 'react';
import { Form, Modal, Button, Row, Spinner, InputGroup } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import './FormPlanCuotas.scss';
import { REQUIRED, TYPENUMBER, TYPE_NUMBER_POSITIVE } from '../../../../helpers/validationForms';
import { useParams } from 'react-router-dom';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { guardarPension } from '../../../../actions/cursosActions';
import useListaPension from '../../../../hooks/useListaPension';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const FormPlanCuotas = ({ onCloseModal, plan, editarPlan }) => {

  const { idProyecto } = useParams();
  const { id_plan: idPlan, dt_plan: cuotasPlan } = plan;
  const dispatch = useDispatch();
  const { loadingPlan } = useSelector(state => state.cursos);
  const { pensiones: cuotas, setPensiones: setCuotas, mostrarPensiones } = useListaPension(cuotasPlan || [], 3,null,editarPlan);

  const formik = useFormik({
    initialValues: {
      monto: '',
      fecha_limite: '',
    },
    validationSchema: yup.object({
      monto: yup.number().typeError(TYPENUMBER).positive(TYPE_NUMBER_POSITIVE).required(REQUIRED),
      fecha_limite: yup.string().required(REQUIRED),
    })
  });

  const handleClickAddCuota = () => {
    const validateMonto = yup.number().typeError(TYPENUMBER).positive(TYPE_NUMBER_POSITIVE).required(REQUIRED) 
    const validateFecha = yup.string().required(REQUIRED);
    
    if(!validateMonto.isValidSync(formik.values.monto)) {
      formik.setFieldError('monto', REQUIRED);
      return;
    }
    if(!validateFecha.isValidSync(formik.values.fecha_limite)) {
      formik.setFieldError('fecha_limite', REQUIRED);
      return;
    }
    setCuotas([
      ...cuotas,
      {
        id_dt_plan: `c-${new Date().getTime()}`,
        monto: formik.values.monto,
        fecha_limite: formik.values.fecha_limite,
        id_tp_concepto: 2
      }
    ])
    formik.setFieldValue('monto', '');
    formik.setFieldValue('fecha_limite', '');
  }

  const handleSubmit = async e => {
    e.preventDefault();
    //Funcion para guardar las cuotas
    await dispatch(guardarPension(idProyecto, idPlan, cuotas, 3, onCloseModal));
  }

  return ( 
    <Form className='FormPlanCuotas' onSubmit={handleSubmit}>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Nombre de plan</Form.Label>
          <Form.Control type='text' defaultValue={plan.nombre_plan} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Tipo de plan</Form.Label>
          <Form.Control type='text' defaultValue={plan.tp_plan} disabled />
        </Form.Group>
        <h6 className='form-header ml-0'>Cuotas</h6>
        <Form.Group>
          <Form.Label>Monto de cuota</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>S/</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type='text'
              name='monto'
              value={formik.values.monto}
              onChange={formik.handleChange}
              isInvalid={formik.errors.monto}
            />
          </InputGroup>
          <Form.Control.Feedback type='invalid'>
            {formik.errors.monto}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Fecha de vencimiento</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type='date'
              name='fecha_limite'
              value={formik.values.fecha_limite}
              onChange={formik.handleChange}
              isInvalid={formik.errors.fecha_limite}
            />
          </InputGroup>
          <Form.Control.Feedback type='invalid'>
            {formik.errors.fecha_limite}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='text-right'>
          <Button variant='info' onClick={handleClickAddCuota}>
            Agregar
          </Button>
        </Form.Group>
        <Form.Group>
          <Form.Label>Cuotas registradas</Form.Label>
          {mostrarPensiones()}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='dark' onClick={onCloseModal}>
          Cancelar
        </Button>
        <Button className='btn-spinner' disabled={loadingPlan} type='submit' variant='info'>
          {
            loadingPlan && <Spinner animation='border' size='sm' />
          }
          Guardar
        </Button>
      </Modal.Footer>
    </Form>
  );
}
 
export default FormPlanCuotas;