import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Spinner, InputGroup, FormCheck, FormGroup } from 'react-bootstrap';
import useSimpleSelect from '../../../../hooks/useSimpleSelect';
import useListaPension from '../../../../hooks/useListaPension';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { REQUIRED, TYPENUMBER, TYPE_NUMBER_POSITIVE } from '../../../../helpers/validationForms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faMoneyBill } from '@fortawesome/free-solid-svg-icons';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { editarPlanProyecto, nuevoPlan } from '../../../../actions/cursosActions';
import { borrarPlan } from '../../../../reducers/CursosReducer';

const FormNuevoPlan = ({ onCloseModal, plan: datosPlan, idTipoModalidad }) => {

  const { SimpleSelect } = useSimpleSelect(options());
  const { idProyecto } = useParams();
  const { loadingPlan } = useSelector(state => state.cursos);
  const [checkboxes, setCheckboxes] = useState({
    matricula: false,
    certificado: false,
  });
  const [isReadOnly, setIsReadOnly] = useState(false);

  const { pensiones, setPensiones, mostrarPensiones2 } = useListaPension([], idTipoModalidad, checkboxes);
  const planesRedux = useSelector(state => state.cursos.planes);


  const dispatch = useDispatch();



  
  const formik = useFormik({
    initialValues: datosPlan ? { ...datosPlan, plan: { value: datosPlan.id_tp_plan, label: datosPlan.tp_plan } } : initialValues(idTipoModalidad, idProyecto),
    validationSchema: yup.object({
      id_tp_plan: yup.string().required(REQUIRED),
      plan: yup.object().required(REQUIRED),
      nombre_plan: yup.string().required(REQUIRED),
      plan: yup.object().when('datosPlan', {
        is: null, // Si `datosPlan` es nulo, `plan` es requerido
        then: schema => schema.required(REQUIRED),
      }),
      monto: yup.number().when('datosPlan', {
        is: null,
        then: schema => schema.required(REQUIRED),
      }),
      cantidad_mensualidades: yup.number().typeError(TYPENUMBER).positive(TYPE_NUMBER_POSITIVE)
        .when('datosPlan', {
          is: null,
          then: schema => schema.required(REQUIRED),
        }),
      monto_matricula: yup.number().typeError(TYPENUMBER).min(0, TYPE_NUMBER_POSITIVE)
        .when('datosPlan', {
          is: null,
          then: schema => schema.required(REQUIRED),
        }),
      monto_certficado: yup.number().typeError(TYPENUMBER).min(0, TYPE_NUMBER_POSITIVE)
        .when('datosPlan', {
          is: null,
          then: schema => schema.required(REQUIRED),
        }),   }),
    validateOnChange: false,  // Desactiva la validación en cada cambio
    validateOnBlur: true, 
    
    onSubmit: async valores => {

      if (datosPlan) {
        dispatch(editarPlanProyecto(idProyecto, valores, onCloseModal));
      } else if(planesRedux) {
        dispatch(nuevoPlan(idProyecto, valores, onCloseModal,planesRedux));
      }
    }
  });

  //ver la vista previa
  const handleClickVistaPrev = async() => {
    
    const validateMonto = yup.number().typeError(TYPENUMBER).positive(TYPE_NUMBER_POSITIVE).required(REQUIRED)
    const validateCantidadMensualidades = yup.number().typeError(TYPENUMBER).positive(TYPE_NUMBER_POSITIVE).required(REQUIRED);

    if (!validateMonto.isValidSync(formik.values.monto)) {
      formik.setFieldError('monto', REQUIRED);
      return;
    }
    if ((idTipoModalidad !==2 ) && !validateCantidadMensualidades.isValidSync(formik.values.cantidad_mensualidades)) {
      formik.setFieldError('cantidad_mensualidades', REQUIRED);
      return;
    }


    if(idTipoModalidad==2)
      formik.values.cantidad_mensualidades = 1;

    setPensiones([{
      id_dt_plan: `c-${new Date().getTime()}`,
      monto: formik.values.monto,
      id_tp_concepto: formik.values.id_tp_concepto,
      cantidad_mensualidades: formik.values.cantidad_mensualidades,
      monto_matricula: formik.values.monto_matricula,
      monto_certificado: formik.values.monto_certificado,
    }]);

    //console.log('pensiones',pensiones);
    //formik.setFieldValue('monto', '');
    //formik.setFieldValue('fecha_limite', '');
    //formik.setFieldValue('id_tp_concepto', '');
  }
  //cambio de matricula/certificado
  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [id]: checked,
    }));
  };

  const handleSavePensiones = () => {

  }

  useEffect(() => {
    if(checkboxes.matricula || checkboxes.certificado) {
      setIsReadOnly(true);
    }else {
      setIsReadOnly(false);
    }
  }, [checkboxes]);

  return (
    
    <Form onSubmit={formik.handleSubmit}>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Nombre del plan</Form.Label>
          <Form.Control
            type='text'
            name='nombre_plan'
            value={formik.values.nombre_plan}
            onChange={formik.handleChange}
            isInvalid={formik.errors.nombre_plan}
          />
          <Form.Control.Feedback type='invalid'>
            {formik.errors.nombre_plan}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Seleccione tipo de plan</Form.Label>
          <SimpleSelect
            value={formik.values.plan}
            onChange={e => {
              formik.setFieldValue('plan', e)
              formik.setFieldValue('id_tp_plan', e.value)
            }}
          />
          {
            formik.errors.id_tp_plan && <Form.Text className='text-danger'>
              {formik.errors.id_tp_plan}
            </Form.Text>
          }
        </Form.Group>
        {datosPlan ? null :
        <>
        <Form.Group>
          <Form.Label>Monto</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>S/</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type='number'
              name='monto'
              value={formik.values.monto}
              onChange={formik.handleChange}
              isInvalid={formik.errors.monto}
              readOnly={isReadOnly}
            />
          </InputGroup>
          <Form.Control.Feedback type='invalid'>
            {formik.errors.monto}
          </Form.Control.Feedback>
        </Form.Group>
        {idTipoModalidad != 2 ?
        <Form.Group>
          <div className='d-flex'>
            <FormCheck
              type='checkbox'
              id='matricula'
              label='Matricula'
              checked={checkboxes.matricula}
              onChange={handleCheckboxChange}
              className="mr-3"

            />
            <FormCheck
              type='checkbox'
              id='certificado'
              label='Certificado'
              checked={checkboxes.certificado}
              onChange={handleCheckboxChange}
            />
          </div>
        </Form.Group>
        : null} 
        {checkboxes.matricula ?
          <Form.Group>
            <Form.Label>Monto matricula</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  S/
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type='number'
                name='monto_matricula'
                value={formik.values.monto_matricula}
                onChange={formik.handleChange}
                isInvalid={formik.errors.monto_matricula}
              />
            </InputGroup>
          </Form.Group>
          : null}
        {checkboxes.certificado ?
          <Form.Group>
            <Form.Label>Monto certificado</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  S/
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type='number'
                name='monto_certificado'
                value={formik.values.monto_certificado}
                onChange={formik.handleChange}
                isInvalid={formik.errors.monto_certificado}
              />
            </InputGroup>
          </Form.Group>
          : null}
        {idTipoModalidad != 2 ?
          <FormGroup>
            <Form.Label>Cantidad de Mensualidades</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faMoneyBill} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type='number'
                name='cantidad_mensualidades'
                value={formik.values.cantidad_mensualidades}
                onChange={formik.handleChange}
                isInvalid={formik.errors.cantidad_mensualidades}
              />
            </InputGroup>
            <Form.Control.Feedback type='invalid'>
              {formik.errors.cantidad_mensualidades}
            </Form.Control.Feedback>
          </FormGroup> : null
        }


        <Form.Group className='text-right'>
          <Button 
          variant='info' 
          onClick={handleClickVistaPrev}
          >
            Actualizar
          </Button>
        </Form.Group>
        
        
        <Form.Group>
          {mostrarPensiones2()}
        </Form.Group>
        </>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant='dark' disabled={loadingPlan} onClick={onCloseModal}>
          Cancelar
        </Button>
        <Button variant='info' disabled={loadingPlan} type='submit' className='btn-spinner'>
          {
            loadingPlan && <Spinner animation='border' size='sm' />
          }
          {
            datosPlan ? 'Guardar' : 'Agregar'
          }
        </Button>
      </Modal.Footer>
    </Form>
  );
}

export default FormNuevoPlan;

const initialValues = (idTipoModalidad, idProyecto) => ({
  id_tp_plan: '',
  plan: null,
  nombre_plan: '',
  id_tp_modalidad: idTipoModalidad,
  id_proyecto: idProyecto,
  monto_matricula: 0,
  monto_certficado: 0
})

const options = () => [
  {
    value: 1,
    label: 'GENERAL'
  },
  {
    value: 2,
    label: 'CORPORATIVO'
  }
]

