import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { actualizarTema } from '../../../actions/cursosActions';

const EditForoModal = ({ isOpen, onClose, tema, idProyecto }) => {

  const dispatch = useDispatch();

  // Estado local para los campos del formulario
  const [titulo, setTitulo] = useState('');
  const [contenido, setContenido] = useState('');

  // Efecto para cargar los datos del tema al abrir el modal
  useEffect(() => {
    if (tema) {
      setTitulo(tema.titulo_tema);
      setContenido(tema.descripcion_tema);
    }
  }, [tema]);

  // Función para manejar la actualización del tema
  const handleUpdate = () => {
    const temaActualizado = {
      ...tema,
      titulo_tema: titulo,
      descripcion_tema: contenido,
      id_proyecto: idProyecto
    };
    dispatch(actualizarTema(temaActualizado));
    onClose();
  };

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Tema</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="titulo">
            <Form.Label>Título</Form.Label>
            <Form.Control
              type="text"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
              placeholder="Ingrese el título del tema"
            />
          </Form.Group>
          <Form.Group controlId="contenido">
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={contenido}
              onChange={(e) => setContenido(e.target.value)}
              placeholder="Ingrese la descripción del tema"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleUpdate}>
          Guardar Cambios
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditForoModal;
