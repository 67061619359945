import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Table } from 'react-bootstrap';
import PensionesItem from '../view/components/ExaminarProyecto/PensionesItem/PensionesItem';
import Fatal from '../view/components/MessageAlert/Fatal';

const useListaPension = (pensionesParams, idModalidad, checkboxes, editarPlan) => {

  const [pensiones, setPensiones] = useState(pensionesParams);
  const proyecto = useSelector(state => state.cursos.proyecto);

  const handleEliminarCuota = (id) => {
    const nuevasCuotas = [...pensiones].filter(cuota => cuota.id_dt_plan !== id);
    setPensiones(nuevasCuotas);
  }

  const mostrarPensiones2 = () => {
    if (!pensiones.length) {
      return <Fatal message='No hay pensiones registradas' />;
    } else if((!pensiones[0].cantidad_mensualidades||pensiones[0].cantidad_mensualidades===0) && idModalidad !== 2){
      return <Fatal message='Tienes que indicar las mensualidades' />;
    }else{
      return (
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Concepto</th>
              <th>Monto</th>
              <th>Fecha límite</th>
            </tr>
          </thead>
          <tbody>
            {(() => {
              let cantidad_mensualidades;
              if(idModalidad === 2){
                cantidad_mensualidades = 1;
              }else{
                 cantidad_mensualidades = pensiones[0]?.cantidad_mensualidades;
              }
              let id_tp_concepto;
              if(checkboxes.matricula) {
                
                cantidad_mensualidades= cantidad_mensualidades + 1;
              }
              if(checkboxes.certificado) {
                
                cantidad_mensualidades= cantidad_mensualidades + 1;
              }
              const fechaInicio = proyecto.horario.fecha_inicio_proyecto; 
              return [...Array(cantidad_mensualidades)].map((_, i) => {
                let monto = pensiones[0]?.monto;
                let indexPension = i;
                let nombreConcepto;
                if(checkboxes.matricula)
                  indexPension = i - 1;
                if(idModalidad === 1){
                  id_tp_concepto = 2;
                  nombreConcepto = 'MENSUALIDAD';
                }else if(idModalidad === 3){
                  id_tp_concepto = 4;
                  nombreConcepto = 'CUOTA';
                }else if(idModalidad === 2){
                  id_tp_concepto = 3;
                  nombreConcepto = 'INVERSIÓN AL CONTADO';
                  indexPension = null;
                }
                
                let fechaMensualidad = new Date(fechaInicio);
                if (checkboxes.matricula && i === 0) {
                  monto = pensiones[0]?.monto_matricula;
                  nombreConcepto = 'MATRICULA';
                  id_tp_concepto = 1;
                  indexPension = null;
                }  else if (i === 0) {
                } else {

                  if(checkboxes.certificado && i === cantidad_mensualidades - 1){
                    monto = pensiones[0]?.monto_certificado;
                    nombreConcepto = 'CERTIFICADO';
                    id_tp_concepto = 5;
                    indexPension = null;
                  }

                  fechaMensualidad.setMonth(fechaMensualidad.getMonth() + i, 1);


                  const mes = fechaMensualidad.getMonth();
                  const year = fechaMensualidad.getFullYear();
                  if (mes == 1) { // Febrero
                    const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
                    fechaMensualidad = new Date(year, mes, isLeapYear ? 29 : 28);
                  } else { // Abril, Junio, Septiembre, Noviembre
                    fechaMensualidad = new Date(year, mes, 30);
                  }
                }

                const fechaFormateada = fechaMensualidad.toISOString().split('T')[0];
                return (
                  <PensionesItem
                    {...pensiones[0]}
                    key={i}
                    item={i}
                    index={indexPension}
                    nombreConcepto={nombreConcepto}
                    handleEliminarCuota={handleEliminarCuota}
                    fecha_limite={fechaFormateada}
                    monto={monto}
                    id_tp_concepto={id_tp_concepto}
                    checkboxes={checkboxes}
                    
                  />
                );
              });
            })()}
          </tbody>
        </Table>
      );
    }
  };



  const mostrarPensiones = () => {
    if (!pensiones.length) {
      return <Fatal message='No hay pensiones registradas' />
    } else {
      let cantidadMensualidad = 0;
      return (
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Concepto</th>
              <th>Monto</th>
              <th>Fecha límite</th>
              <th>Eliminar</th>
            </tr>
          </thead>
          <tbody>
            {[...pensiones].map((pension, index) => {
              let indexPension = null;
              let nombreConcepto = '';
              if (parseInt(pension.id_tp_concepto) === 2) {
                cantidadMensualidad += 1;
                if (idModalidad === 3) {
                  nombreConcepto = 'CUOTA';
                  indexPension = index;
                } else if (idModalidad === 1) {
                  nombreConcepto = 'MENSUALIDAD';
                  indexPension = cantidadMensualidad - 1;
                }
              } else if (parseInt(pension.id_tp_concepto) === 1) {
                indexPension = null;
                nombreConcepto = 'MATRICULA'
              } else {
                indexPension = null;
                nombreConcepto = 'CERTIFICADO'
              }
              return (
                <PensionesItem
                  key={index}
                  item={index}
                  index={indexPension}
                  nombreConcepto={nombreConcepto}
                  handleEliminarCuota={handleEliminarCuota}
                  {...pension}
                  editarPlan={editarPlan}
                />
              )
            })}
          </tbody>
        </Table>
      )
    }
  }

  return { pensiones, setPensiones, mostrarPensiones, mostrarPensiones2 };
}

export default useListaPension;