import React, { useState, useEffect } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import MaterialTableDemo from "../../components/plugins/MaterialTableDemo";
import ModalInsertarTarea from "./ModalInsertartareas";
import SimpleSelect from "../../components/plugins/SimpleSelect";
import {Link} from 'react-router-dom'
import './tareas.scss'
//Redux
import { connect } from "react-redux";
import {
  listarProyectos,
  listarSesionesCursos,
} from "../../../actions/cursosActions";
import {
  listarTareas,
  eliminarTareaAdmin
} from '../../../actions/tareasActions'
import { metodoConfimacion } from "../../../helpers/functions";

const Tareas = (props) => {
  //State props
  const { proyectos, sesionesCurso, tareas } = props;
  //Funciones props
  const { listarProyectos, listarSesionesCursos,eliminarTareaAdmin, listarTareas } = props;

  const [idProyecto, setIdProyecto] = useState({});
  const [idSesion, setIdSesion] = useState({});
  const [selectProyectos, setSelecProyecto] = useState([]);
  const [selectSesiones, setSelectSesiones] = useState([]);
  const [dataTareas, setDataTareas] = useState([])

  const llenarSelectProyectos = () => {
    let dataGenerado = [];
    proyectos.map((proyecto) => {
      dataGenerado = [
        ...dataGenerado,
        {
          value: proyecto.id_proyecto,
          label: proyecto.nombre_proyecto,
        },
      ];
    });

    setSelecProyecto(dataGenerado);
  };

  const llenarSelectSesiones = () => {
    let dataGenerado = [];

    sesionesCurso.map((sesion) => {
      dataGenerado = [
        ...dataGenerado,
        {
          value: sesion.id_sesion,
          label: sesion.nombre_sesion,
        },
      ];
    });
    setSelectSesiones(dataGenerado);
  };

  const eliminarTarea = (idTarea) => {

    const dataMetodo = {
      funcion: eliminarTareaAdmin,
      id: idTarea,
      mensajeAdvertencia: 'Se eliminara las tareas contenidas',
      mensajeExito: 'Eliminado correctamente'
    }

      metodoConfimacion(dataMetodo)
  }

  const llenarDataTareas = () => {
    let dataGenerado = []
    tareas.map(tarea => {
      dataGenerado = [...dataGenerado,{
        id: tarea.id_tarea,
        id_tarea: tarea.id_tarea,
        nombre: tarea.nombre_tarea,
        fecha_subida: tarea.fecha_subida_t,
        hora_subida: tarea.hora_subida_t,
        button: <>
          <button
            type='button'
            className='btn btn-sm btn-eliminar'
            title='Eliminar'
            onClick={() => eliminarTarea(tarea.id_tarea)}
          >
            <i className='fas fa-trash'></i>
          </button>
          <a
            href={`${process.env.REACT_APP_API_URL}/${tarea.ruta_tarea}`}
            className='btn btn-sm btn-descrip'
            title='Ver tarea'
          >
            <i className='fas fa-eye'></i>
          </a>
          <Link
            type='button'
            to={`tareas/${tarea.id_tarea}`}
            className='btn btn-sm btn-ver'
            title='Ver tareas'
          >
            <i className='fas fa-eye'></i>
          </Link>
        </>
      }]
    })

    setDataTareas(dataGenerado)
  }

  useEffect(() => {
    listarProyectos();
  }, []);

  useEffect(() => {
    llenarSelectProyectos();
    llenarSelectSesiones();
  }, [proyectos, sesionesCurso]);

  useEffect(() => {
    if (idProyecto) {
      listarSesionesCursos(idProyecto.value);
     
    }
  }, [idProyecto]);

  useEffect(() => {
    if (idSesion !== undefined && Object.keys(idSesion).length > 0) {
        listarTareas(idSesion.value)
    }
  }, [idSesion, idProyecto])

  useEffect(() => {
    llenarDataTareas()
  }, [tareas])

  return (
    <Layout>
      <HeaderContent title="Tareas" />

      <Breadcrumb
        link={[
          { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
          { url: "/tareas", nombre: "Tareas" },
        ]}
      />

      <div className="clearfix tareas">
        <div className="container-fluid px-0 content-actions my-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row recursos__content-filtro">
                <div className="col-md-4 filtro">
                  <label htmlFor="">Producto</label>
                  <SimpleSelect
                    options={selectProyectos}
                    getSelection={setIdProyecto}
                    menuPortalTarget={document.body} // Hace que el menú se renderice fuera del contenedor
                  />
                </div>
                <div className="col-md-4 filtro">
                  <label htmlFor="">Sesiones</label>
                  <SimpleSelect
                    options={selectSesiones}
                    getSelection={setIdSesion}
                    menuPortalTarget={document.body} // Hace que el menú se renderice fuera del contenedor
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-primary float-right text-white"
                data-toggle="modal"
                data-target="#modal-tarea"

              >
                <p className="mb-0 d-flex align-items-center">
                  <i className="material-icons">add</i>
                  <span className="text-white"> Añadir tareas</span>
                </p>
              </button>
            </div>
          </div>
        </div>

        <div className="p-0 mb-5 bg-white">
          <div className="continer-fluid border-bottom-0 border">
            <h5
              className="p-3 d-flex align-items-center mb-0"
              style={{ color: "#434343" }}
            >
              <i className="fas fa-list pr-2"></i>Lista de tareas
            </h5>
          </div>
          <div className="tabla border">
            <React.Fragment>
              <MaterialTableDemo colums={colums} tabla={dataTareas} />
            </React.Fragment>
          </div>
        </div>
      </div>
      <ModalInsertarTarea 
        idSesion={idSesion.value}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  proyectos: state.cursos.proyectos,
  sesionesCurso: state.cursos.sesionesCurso,
  tareas: state.tareas.tareas
});

const mapDispatchToProps = {
  listarProyectos,
  listarSesionesCursos,
  listarTareas,
  eliminarTareaAdmin
};

export default connect(mapStateToProps, mapDispatchToProps)(Tareas);

const colums = [
  {
    text: "Id",
    key: "id_tarea",
    align: "left",
    sortable: true,
    className: "type_of_food",
  },
  {
    text: "Nombre sesión",
    key: "nombre",
    align: "left",
    sortable: true,
    className: "type_of_food",
  },
  {
    text: "Fecha",
    key: "fecha_subida",
    align: "left",
    sortable: true,
    className: "type_of_food",
  },
  {
    text: "Fecha",
    key: "hora_subida",
    align: "left",
    sortable: true,
    className: "type_of_food",
  },
  {
    text: "Acciones",
    key: "button",
    align: "left",
    sortable: true,
    className: "type_of_food",
  },
];
