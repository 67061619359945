import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useGetCapacitacionControl = idCapacitacionParam => {
  
  const { idCapacitacion } = useParams();
  const idRetorno = !idCapacitacionParam ? idCapacitacion : idCapacitacionParam
  const areaTrabajo = useSelector(state => state.control_capacitacion.area_trabajo)
  return [areaTrabajo, idRetorno]

}
 
export default useGetCapacitacionControl;