import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
//import '../../../../public/images/implementacion_formula.png';

const InfoModal = ({ tipo }) => {

    const [contenido, setContenido] = useState('');
    useEffect(() => {

    }, []);

    if (tipo === 'Implementacion') {
        return (
            <div className="container p-4">
                <Form>
                    <Form.Group>
                        <div className="kpi-description">
                            <div className='mb-3'>
                                <p>Este KPI mide la <strong>eficiencia en la implementación de contenidos</strong> en programas de especialización. El área de curaduría debe garantizar que, para cada producto activo (programa), todas las sesiones programadas en un mes estén <strong>curadas y listas al menos 7 días antes</strong> de su dictado, según las fechas registradas en el sistema.</p>
                            </div>

                            <div className='mb-3'>
                                <h5>Propósito:</h5>
                                <p>Asegurar que los contenidos se preparen y validen con antelación, permitiendo una ejecución fluida de las clases y garantizando la calidad académica en la educación ejecutiva.</p>
                            </div>
                            <div className='mb-3'>
                                <h5>Fórmula:</h5>
                                <img className='img-fluid' src="/images/implementacion_formula.png" alt="Formula for KPI" />
                            </div>
                            <div className='mb-3'>
                                <h5>Meta:</h5>
                                <p>100% de las sesiones listas 7 días antes del dictado.</p>
                            </div>

                            <div className='mb-3'>
                                <h5>Plan de Acción:</h5>
                                <ul className='mb-3'>
                                    <li><strong>Planificación del Calendario:</strong> Revisar las fechas de todas las sesiones activas al inicio de cada mes y asignar tareas de curaduría con suficiente anticipación.</li>
                                    <li><strong>Control Diario de Progreso:</strong> Llevar un registro en tiempo real del avance en la curaduría de contenidos para detectar y resolver posibles atrasos.</li>
                                    <li><strong>Coordinación con Docentes:</strong> Asegurar una comunicación constante con los profesores para alinear expectativas y resolver dudas que puedan surgir sobre los materiales.</li>
                                    <li><strong>Informe de Cumplimiento:</strong> Generar un reporte mensual que compare el avance real con la meta del KPI, identificando oportunidades de mejora.</li>
                                </ul>
                            </div>
                            <p>Este KPI garantiza que la curaduría de contenidos se realice de manera eficiente y oportuna, evitando retrasos que afecten la experiencia de los alumnos y asegurando el éxito de los programas ejecutivos.</p>
                        </div>
                    </Form.Group>
                </Form>
            </div>
        );
    }

    if (tipo === 'Control') {
        return (
            <div className="container p-4">
                <Form>
                    <Form.Group>
                        <div className="kpi-description">
                            <div className='mb-3'>
                                <p>Este KPI mide la <strong>calidad de la enseñanza </strong> a través del nivel de satisfacción de los alumnos, con la meta de alcanzar un <strong>90% o más de satisfacción</strong> por sesión. Al finalizar cada clase, los alumnos completan una encuesta donde califican a los profesores según su adaptación a la <strong>metodología andragógica</strong> de INEDI Posgrados, enfocada en el aprendizaje para adultos.</p>
                            </div>

                            <div className='mb-3'>
                                <h5>Propósito:</h5>
                                <p>Asegurar que los profesores brinden clases alineadas con la metodología institucional, ofreciendo experiencias de aprendizaje significativas para adultos. El área de curaduría juega un rol clave sensibilizando y capacitando a los docentes para que logren esta adaptación.</p>
                            </div>
                            <div className='mb-3'>
                                <h5>Fórmula:</h5>
                                <img className='img-fluid' src="/images/control_formula.png" alt="Formula for KPI" />
                            </div>
                            <div className='mb-3'>
                                <h5>Meta:</h5>
                                <p>90% o más de satisfacción por sesión.</p>
                            </div>

                            <div className='mb-3'>
                                <h5>Plan de Acción:</h5>
                                <ul className='mt-2'>
                                    <li><strong>Encuestas al Final de Cada Sesión:</strong> Monitorear el nivel de satisfacción docente y recopilar retroalimentación directa de los alumnos.</li>
                                    <li><strong>Capacitación Continua:</strong> El área de curaduría sensibiliza y capacita a los profesores en la metodología andragógica para asegurar su adaptación a las necesidades de los alumnos adultos.</li>
                                    <li><strong>Mejora Continua:</strong> Identificar y trabajar con los profesores cuyas calificaciones estén por debajo del 90%, proporcionando coaching personalizado para elevar su desempeño.</li>
                                </ul>
                            </div>
                            <p>Este KPI asegura una enseñanza alineada con los principios institucionales, mejorando la experiencia de los alumnos y motivando a los profesores a ofrecer clases de alta calidad.</p>
                        </div>
                    </Form.Group>
                </Form>
            </div>
        );
    }

    if (tipo === 'Organizacion') {
        return (
            <div className="container p-4">
                <Form>
                    <Form.Group>
                        <div className="kpi-description">
                            <div className='mb-3'>
                                <p>Este KPI mide la <strong>disponibilidad del 100% de los recursos </strong> para cada sesión, asegurando que estén subidos y listos antes del inicio de la clase. Los recursos incluyen: </p>
                                <ul className='mt-2'>
                                    <li><strong>Sesión online</strong> (enlace de Zoom)</li>
                                    <li><strong>Sesión offline</strong> (enlace de la grabación de la clase)</li>
                                    <li><strong>Podcast:</strong> Audio de 5 minutos con introducción al tema por el profesor</li>
                                    <li><strong>Materiales:</strong> PPT y lecturas principales, secundarias, leyes, normas, etc.</li>
                                    <li><strong>Actividad:</strong> Cuaderno de trabajo con ejercicios prácticos</li>
                                    <li><strong>Quiz:</strong> Evaluación con 5 preguntas para medir el aprendizaje</li>
                                </ul>
                            </div>

                            <div className='mb-3'>
                                <h5>Propósito:</h5>
                                <p>Garantizar la <strong>calidad y preparación oportuna</strong> de los recursos para cada sesión, mejorando la experiencia de aprendizaje y facilitando el trabajo del docente.</p>
                            </div>
                            <div className='mb-3'>
                                <h5>Fórmula:</h5>
                                <img className='img-fluid' src="/images/organizacion_formula.png" alt="Formula for KPI" />
                            </div>
                            <div className='mb-3'>
                                <h5>Meta:</h5>
                                <p>100% de las sesiones con recursos completos.</p>
                            </div>

                            <div className='mb-3'>
                                <h5>Plan de Acción:</h5>
                                <ul className='mt-2'>
                                    <li><strong>Control del Calendario:</strong> Verificar semanalmente las sesiones programadas para asegurar que los recursos se suban con anticipación.</li>
                                    <li><strong>Coordinación Docente:</strong> Alinear con los profesores la entrega de materiales y grabaciones.</li>
                                    <li><strong>Reporte Mensual:</strong> Generar informes de cumplimiento para detectar y corregir áreas críticas.</li>
                                </ul>
                            </div>
                            <p>Este KPI garantiza que cada sesión cuente con los recursos necesarios, asegurando la <strong>calidad y continuidad</strong> del proceso educativo.</p>
                        </div>
                    </Form.Group>
                </Form>
            </div>
        );
    }

    if (tipo === 'Recaudacion') {
        return (
            <div className="container-fluid p-4">
                <Form>
                    <Form.Group>
                        <div className="kpi-description">
                            <div className='mb-3'>
                                <p>Este KPI mide el porcentaje del total de mensualidades de los alumnos que se logra cobrar cada mes, evaluando el desempeño del ejecutivo de experiencia en la gestión de cobros. El objetivo es alcanzar al menos <strong>90% de recaudación mensual </strong>, considerando que el 10% restante puede corresponder a situaciones fuera del control del ejecutivo, como problemas económicos de los alumnos o situaciones excepcionales que dificulten el pago.</p>
                            </div>

                            <div className='mb-3'>
                                <h5>Propósito:</h5>
                                <p>El propósito de este KPI es asegurar la <strong>sostenibilidad financiera</strong> de la empresa mediante una gestión eficiente de los cobros, fortaleciendo la relación con los alumnos a través de una comunicación clara y oportuna. Cumplir este KPI significa que el ejecutivo de experiencia contribuye directamente al buen funcionamiento del negocio, garantizando ingresos estables y mejorando la satisfacción del alumno al evitar situaciones de morosidad prolongada.</p>
                            </div>
                            <div className='mb-3'>
                                <h5>Fórmula:</h5>
                                <img className='img-fluid' src="/images/recaudacion_formula.png" alt="Formula for KPI" />
                            </div>
                            <div className='mb-3'>
                                <h5>Frecuencia de medición:</h5>
                                <p>Mensual</p>
                            </div>
                            <div className='mb-3'>
                                <h5>Meta:</h5>
                                <p>90% o más de recaudación cada mes.</p>
                            </div>

                            <div className='mb-3'>
                                <h5>Plan de acción sugerido:</h5>
                                <ul className='mt-2'>
                                    <div>
                                        <li><h8><strong>Afiliación al Pago Recurrente</strong></h8></li>

                                        <p>
                                            Incentivar la afiliación de los alumnos al sistema de <strong>pago recurrente</strong>, donde las mensualidades se debitarán automáticamente cada mes de sus cuentas bancarias o tarjetas sin necesidad de gestión manual. Esto reducirá el trabajo operativo y aumentará la probabilidad de alcanzar la meta del 90%.
                                        </p>

                                        <ul className='mt-2 mb-2'>
                                            <li><strong>Acción recomendada</strong>: Comunicar los beneficios del pago recurrente, como la comodidad y evitar retrasos.</li>
                                            <li><strong>Meta interna</strong>: Aumentar cada mes el porcentaje de alumnos afiliados al pago automático para minimizar las gestiones de cobro.</li>
                                        </ul>
                                    </div>
                                    <div>

                                        <li><h8><strong>Generación de Confianza</strong></h8></li>

                                        <p>
                                            Establecer una<strong>relación cercana y transparente</strong>con los alumnos para fortalecer la confianza en la gestión de pagos. Escuchar sus inquietudes y, cuando sea necesario, ofrecer alternativas o facilidades de pago que se alineen con sus necesidades.
                                        </p>

                                        <ul className='mt-2 mb-2'>
                                            <li><strong>Acción recomendada</strong>: Realizar llamadas o enviar mensajes personalizados, demostrando empatía ante posibles inconvenientes.</li>
                                            <li><strong>Resultado esperado</strong>: Mejor retención de los alumnos y menor tasa de morosidad, evitando conflictos relacionados con los pagos.</li>
                                        </ul>
                                    </div>

                                    <div>
                                        <li><h8><strong>Seguimiento Proactivo y Medición a través de Campañas y Embudos</strong></h8></li>

                                        <p>
                                            Desarrollar <strong>campañas de recordatorio de pagos</strong>(vía correo, WhatsApp u otros canales) antes y después de las fechas de vencimiento. Estas campañas deben ser <strong>monitoreadas mediante embudos de conversión</strong> para evaluar su efectividad en cada etapa, desde la apertura del mensaje hasta el pago realizado.
                                        </p>

                                        <ul className='mt-2 mb-2'>
                                            <li><strong>Acción recomendada</strong>:  Implementar un sistema de funnel o embudo de seguimiento para identificar cuántos alumnos respondieron a los recordatorios y realizaron su pago. Ajustar las campañas según los resultados.</li>
                                            <li><strong>Resultado esperado</strong>: Mayor eficiencia en la gestión de cobros al optimizar las campañas y focalizar los esfuerzos en los alumnos con más probabilidades de pago.</li>
                                        </ul>
                                    </div>
                                </ul>


                            </div>
                            <p>Alcanzar esta meta de recaudación es clave no solo para el crecimiento financiero de la empresa, sino también para consolidar una experiencia positiva para los alumnos. Un cobro eficiente refleja una gestión organizada y confiable, lo que mejora la reputación del área y del ejecutivo. Además, cumplir con esta meta brinda la <strong>satisfacción personal de lograr objetivos mensuales claros</strong>, alineados con el éxito de la organización.</p>
                        </div>
                    </Form.Group>
                </Form>
            </div>
        );
    }

    if (tipo === 'Retencion') {
        return (
            <div className="container p-4">
                <Form>
                    <Form.Group>
                        <div className="kpi-description">
                            <div className='mb-3'>
                                <h5>Descripción:</h5>
                                <p>Este KPI mide el porcentaje de alumnos que se mantienen activos durante el período, evaluando su permanencia no solo por el pago de mensualidades, sino también por su progreso académico, asistencia y acumulación de puntos.</p>
                            </div>

                            <div className='mb-3'>
                                <h5>Propósito:</h5>
                                <p>Garantizar que los alumnos no abandonen sus estudios, fomentando el compromiso mediante seguimiento de su progreso académico y motivándolos a alcanzar metas significativas en el sistema de puntos.</p>
                            </div>
                            <div className='mb-3'>
                                <h5>Fórmula:</h5>
                                <img className='img-fluid' src="/images/retencion_formula.png" alt="Formula for KPI" />
                            </div>
                            <div className='mb-3'>
                                <h5>Meta:</h5>
                                <p>90% o más de retención mensual.</p>
                            </div>

                            <div className='mb-3'>
                                <h5>Plan de Acción:</h5>
                                <ul className='mt-2'>
                                    <li><strong>Seguimiento del Progreso:</strong> Monitorear la asistencia y el puntaje acumulado de los alumnos para identificar riesgos de deserción temprana.</li>
                                    <li><strong>Uso del Sistema de Puntos:</strong> Asegurar que los alumnos alcancen al menos <strong>700 puntos</strong> y motivar a quienes lleguen a <strong>1,000 puntos</strong> con reconocimientos académicos, como publicaciones o acceso a bolsas de trabajo.</li>
                                    <li><strong>Recuperación de Alumnos:</strong> Los alumnos que abandonan pasan al <strong>reporte de SOS</strong>, donde se aplican estrategias para reintegrarlos al mismo curso o transferirlos a productos similares, siguiendo las condiciones establecidas en la normativa vigente.</li>
                                </ul>
                            </div>
                            <p>Este KPI asegura que los alumnos no solo se mantengan matriculados, sino también comprometidos con su crecimiento académico, fortaleciendo su experiencia y brindándoles oportunidades de desarrollo profesional.</p>
                        </div>
                    </Form.Group>
                </Form>
            </div>
        );
    }

    if (tipo === 'Calidad') {
        return (
            <div className="container p-4">
                <Form>
                    <Form.Group>
                        <div className="kpi-description">
                            <div className='mb-3'>
                                <p>Este KPI mide la <strong>calidad de la enseñanza</strong> a través del nivel de satisfacción de los alumnos, con la meta de alcanzar un <strong>90% o más de satisfacción</strong> por sesión. Al finalizar cada clase, los alumnos completan una encuesta donde califican a los profesores según su adaptación a la <strong>metodología andragógica</strong> de INEDI Posgrados, enfocada en el aprendizaje para adultos.</p>
                            </div>

                            <div className='mb-3'>
                                <h5>Propósito:</h5>
                                <p>Asegurar que los profesores brinden clases alineadas con la metodología institucional, ofreciendo experiencias de aprendizaje significativas para adultos. El área de curaduría juega un rol clave sensibilizando y capacitando a los docentes para que logren esta adaptación.</p>
                            </div>
                            <div className='mb-3'>
                                <h5>Fórmula:</h5>
                                <img className='img-fluid' src="/images/control_formula.png" alt="Formula for KPI" />
                            </div>
                            <div className='mb-3'>
                                <h5>Meta:</h5>
                                <p>90% o más de satisfacción por sesión.</p>
                            </div>

                            <div className='mb-3'>
                                <h5>Plan de Acción:</h5>
                                <ul className='mt-2'>
                                    <li><strong>Encuestas al Final de Cada Sesión:</strong> Monitorear el nivel de satisfacción docente y recopilar retroalimentación directa de los alumnos.</li>
                                    <li><strong>Capacitación Continua:</strong> El área de curaduría sensibiliza y capacita a los profesores en la metodología andragógica para asegurar su adaptación a las necesidades de los alumnos adultos.</li>
                                    <li><strong>Mejora Continua:</strong> Identificar y trabajar con los profesores cuyas calificaciones estén por debajo del 90%, proporcionando coaching personalizado para elevar su desempeño.</li>
                                </ul>
                            </div>
                            <p>Este KPI asegura una enseñanza alineada con los principios institucionales, mejorando la experiencia de los alumnos y motivando a los profesores a ofrecer clases de alta calidad.</p>
                        </div>
                    </Form.Group>
                </Form>
            </div>
        );
    }


};

export default InfoModal;