import React, { useState } from 'react'

import Comentario from './Comentario';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';

const Comentarios = ({comentarios}) => {
    const [busqueda,setBusqueda]=useState('')
    const handleChange = e => {
      setBusqueda(e.target.value)
    }

    const comentarioFiltrado = comentarios ? comentarios.filter(
      comentario => {
        return (
          comentario
          .nombre_usuario
          .toLowerCase()
          .includes(busqueda.toLowerCase()) ||
          comentario
          .apellido_usuario
          .toLowerCase()
          .includes(busqueda.toLowerCase()) ||
          comentario
          .nombre_proyecto
          .toLowerCase()
          .includes(busqueda.toLowerCase()) ||
          comentario
          .nombre_sesion
          .toLowerCase()
          .includes(busqueda.toLowerCase()) 
          
        )
      }
    ) : null

    return ( 
        <>
          <div className="col-12">
            <div className="row p-2">
                <h4 className='col-8 font-20 text-uppercase'>
                  
                </h4>
                <div className="col-4 d-flex align-items-center">
                  <input className="form-control" value={busqueda} onChange={handleChange} style={{marginRight:'5px'}} />
                  <FontAwesomeIcon icon={faSearch} size="lg"/>
                </div>
            </div>
            
            <div className="body-calendar my-3 p-2">
              {
                comentarioFiltrado ? comentarioFiltrado.length!==0 ? comentarioFiltrado.map((comentario, i) => (
                    <Comentario key={i} comentario={comentario}/>
                  )) : 'No hay comentarios recientes...': 'No hay comentarios recientes...' 
              }
            </div>
          </div>
        </>
     );
}


export default Comentarios;