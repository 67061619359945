
import React from 'react';
import { Table } from 'react-bootstrap';
//Redux
const ResultadosFiltro = ({resultados}) => {
 // console.log(resultados)
 return (
 
      <div className="row p-2">
       { /*<div className="col-12 mb-2">
         <h4>Campaña: {resultados?.campaign} </h4>   
        </div>*/}
        <div className="col-md-6">

            <div className="embudo">
              <div className="embudo_1">
                <h3>{resultados.resumen.base_leads}</h3>
                <p>Leads recolectados</p>
              </div>
              <div className="embudo_2">
                <h3>{resultados.resumen.contestados}({parseFloat((resultados.resumen.contestados/resultados.resumen.base_leads)*100).toFixed(2)}%)</h3>
                <p>Contestaron</p>
              </div>
              <div className="embudo_3">
                <h3 style={{marginTop:'-30px'}}>{resultados.resumen.clientes}</h3>
                <p style={{marginTop:'-15px'}}>Clientes</p>
              </div>
            </div>
           <div>
              <h4>Inversión</h4>
              <Table className='mb-4'>
                    <thead>
                      <tr>
                        <th>
                        Inversión Total
                        </th>
                        <th>
                        Costo x Lead
                        </th>
                        <th>
                        Costo x Adquisición
                        </th>
                        <th>
                        Conversión(%)
                        </th>
                        <th>
                        Conversión Total(%)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                            <tr>
                            <td style={{textAlign:'center'}}>S/. {resultados.costos.inversion_t}</td>
                            <td style={{textAlign:'center'}}>S/. {isNaN(resultados.costos.inversion_t/resultados.resumen.base_leads) ? 0 : parseFloat(resultados.costos.inversion_t/resultados.resumen.base_leads).toFixed(2) }</td>
                            <td style={{textAlign:'center'}}>S/. {resultados.resumen.clientes > 0 ? isNaN(resultados.costos.inversion_t/resultados.resumen.clientes) ? 0 : parseFloat(resultados.costos.inversion_t/resultados.resumen.clientes).toFixed(2):0 }</td>
                            <td style={{textAlign:'center'}}>{isNaN(resultados.resumen.clientes/resultados.resumen.contestados) ? 0 : parseFloat((resultados.resumen.clientes/resultados.resumen.contestados)*100).toFixed(2)}%</td>
                            <td style={{textAlign:'center'}}>{isNaN(resultados.resumen.clientes/resultados.resumen.base_leads) ? 0 : parseFloat((resultados.resumen.clientes/resultados.resumen.base_leads)*100).toFixed(2)}%</td>
                            </tr>
                    </tbody>
                    
                </Table>

                <h4>Fuentes</h4>
                <Table className='mb-4'>
                <thead>
                      <tr>
                        <th>
                          Fuente
                        </th>
                        <th>
                          Cantidad
                        </th>
                        <th>
                          Costo x Lead
                        </th>
                        <th>
                          Porcentaje (%)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                        {
                          resultados.canales ? resultados.canales.map((canal,i)=>(
                            <tr key={i}>
                            <td>{canal.nombre_fuente? canal.nombre_fuente:'Leads sin canal'}</td>
                            <td style={{textAlign:'right'}}>{canal.cantidad}</td>
                            <td style={{textAlign:'right'}}>{(resultados.costos.inversion_t/canal.cantidad).toFixed(2)}</td>
                            <td style={{textAlign:'right'}}>{parseFloat((canal.cantidad/resultados.resumen.base_leads)*100).toFixed(2)}%</td>
                            </tr>
                          )) : <tr><td colSpan={3} className='text-center'>Sin datos</td></tr>
                        }
                    </tbody>
                </Table>             
           </div> 
        </div>    
        <div className="col-md-6">
        <h4>Contactos</h4>
          <Table className='mb-4'>
              <thead>
                <tr>
          
                  <th>
                    Cantidad
                  </th>
                  <th>
                    Porcentaje (%)
                  </th>
                </tr>
              </thead>
              <tbody>
                  {
                    resultados.contactos ? resultados.contactos.map((fila,i)=>(
                      <tr key={i}>
              
                      <td style={{textAlign:'center'}}>{fila.cantidad}</td>
                      <td style={{textAlign:'center'}}>{parseFloat((fila.cantidad/resultados.resumen.base_leads)*100).toFixed(2)}%</td>
                      </tr>
                    )) : <tr><td colSpan={3} className='text-center'>Sin datos</td></tr>
                  }
              </tbody>
              
          </Table>
          <h4>Tratos</h4>
          <Table className='mb-4'>
              <thead>
                <tr>
                  <th>
                    Fase
                  </th>
                  <th>
                    Cantidad
                  </th>
                  <th>
                    Porcentaje (%)
                  </th>
                </tr>
              </thead>
              <tbody>
                  {
                    resultados.tratos ? resultados.tratos.map((fila,i)=>(
                      <tr key={i}>
                      <td>{fila.nombre_fase}</td>
                      <td style={{textAlign:'right'}}>{fila.cantidad}</td>
                      <td style={{textAlign:'right'}}>{parseFloat((fila.cantidad/resultados.contactos[0].cantidad)*100).toFixed(2)}%</td>
                      </tr>
                    )) : <tr><td colSpan={3} className='text-center'>Sin datos</td></tr>
                  }
              </tbody>
              
          </Table>   
        <h4>Estados</h4>
          <Table className='mb-4'>
              <thead>
                <tr>
                  <th>
                      Descripción
                  </th>
                  <th>
                    Cantidad
                  </th>
                  <th>
                    Porcentaje (%)
                  </th>
                </tr>
              </thead>
              <tbody>
                  {
                    resultados.clasificaciones ? resultados.clasificaciones.map((estado,i)=>(
                      <tr key={i}>
                      <td>{estado.nombre_estado}</td>
                      <td style={{textAlign:'right'}}>{estado.cantidad}</td>
                      <td style={{textAlign:'right'}}>{parseFloat((estado.cantidad/resultados.resumen.base_leads)*100).toFixed(2)}%</td>
                      </tr>
                    )) : <tr><td colSpan={3} className='text-center'>Sin datos</td></tr>
                  }
              </tbody>
              
          </Table>
          <h4>RT2</h4>
          <Table className='mb-4'>
              <thead>
                <tr>
                  <th>
                    Observacion
                  </th>
                  <th>
                    Cantidad
                  </th>
                  <th>
                    Porcentaje (%)
                  </th>
                </tr>
              </thead>
              <tbody>
                  {
                    resultados.observaciones ? resultados.observaciones.map((fila,i)=>(
                      <tr key={i}>
                      <td>{fila.observacion_n}</td>
                      <td style={{textAlign:'right'}}>{fila.cantidad}</td>
                      <td style={{textAlign:'right'}}>{parseFloat((fila.cantidad/resultados.total_rt)*100).toFixed(2)}%</td>
                      </tr>
                    )) : <tr><td colSpan={3} className='text-center'>Sin datos</td></tr>
                  }
              </tbody>
              
          </Table>  
         
        </div>     
      </div>
    
  
  
    );
};

export default ResultadosFiltro;
