import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner, Modal } from "react-bootstrap";
import { updateStateModalCustom } from "../../../../actions/modalActions";
import { faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as moment from "moment";
import TablaPensionFraccion from "../../../../containers/FinanzasContainers/TablaPensionFraccion";
import { useFormik } from "formik";
import * as yup from "yup";
import "./RegistroFactura.scss";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { obtenerIDBoletaSiguiente } from "../../../../actions/pagosActions";

const RegistroFactura = ({ handleFormSubmit }) => {
  const dispatch = useDispatch();
  const [idTpMedioPago, setIdTpMedioPago] = useState('');
  const { matriculaActiva, todo = {}, detallePago } = useSelector(
    (state) => state.pagos.datosFinanzasDiscente
  );
  const {
    numeroDocumento,
    modalidad,
    idPensionFraccion,
    loading,
  } = detallePago;
  const formik = useFormik({
    initialValues: initialValues(idPensionFraccion, todo),
    validationSchema: yup.object(validationSchema(idTpMedioPago)),
    onSubmit: (valores) => {
      handleFormSubmit(valores, matriculaActiva.id_matricula);
    },
    validateOnBlur: true
  });

  const mostrarOptionsbancos = () => {
    return bancos.map((banco) => (
      <option key={banco.id} value={banco.id}>
        {banco.nombre}
      </option>
    ));
  };

  const {
    observaciones,
    fecha_pago,
    dni_ruc,
    id_tp_banco,
    id_tp_medio_pago,
    id_tp_area,
    nro_tarjeta,
    nro_operacion,
    nro_operacion_cliente,
    id_tp_medio_serie,
    codigo
  } = formik.values;

  const handleCloseModal = () => {
    dispatch(updateStateModalCustom());
  };

  useEffect(() => {
    dispatch(obtenerIDBoletaSiguiente(id_tp_medio_serie));
  }, []);

  const mostrarCamposVariables = () => {
    if(idTpMedioPago) {
      switch (idTpMedioPago) {
        case '1':
        case '2':
          return <Col md={4}>
            <Form.Group as={Row}>
              <Form.Label column md={4} htmlFor="id_tp_banco">
                Banco
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  id="id_tp_banco"
                  as="select"
                  name="id_tp_banco"
                  value={id_tp_banco}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.id_tp_banco}
                >
                  <option value="" disabled>
                    --Seleccione--
                  </option>
                  {mostrarOptionsbancos()}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.id_tp_banco}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Col>
        default:
          break;
      }
    }
  }

  useEffect(() => {
    if (numeroDocumento !== "") {
      formik.values.nro_documento = numeroDocumento;
    }
  }, [numeroDocumento]);

  return (
    <div className="RegistroFactura">
      <Form onSubmit={formik.handleSubmit}>
        <fieldset disabled={loading}>
          <Modal.Body>
            <Form.Group as={Row}>
              <Form.Label column md={1}>
                Producto:
              </Form.Label>
              <Col md={11}>
                <p style={{marginTop: '.7rem', marginLeft: '2.2rem'}}>
                  {matriculaActiva.nombre_programa}
                </p>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column md={1}>
                Discente:
              </Form.Label>
              <Col md={10}>
                <p style={{marginTop: '.7rem', marginLeft: '2.2rem'}}>{`${todo.nombres} ${todo.apellidos}`}</p>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col md={4}>
                <Form.Group as={Row}>
                  <Form.Label column md={4} htmlFor="dni">
                    DNI
                  </Form.Label>
                  <Col md={8}>
                    <Form.Control
                      id="dni"
                      defaultValue={dni_ruc}
                      type="text"
                      disabled
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column md={4} htmlFor="id_tp_medio_pago">
                    Medio de pago
                  </Form.Label>
                  <Col md={8}>
                    <Form.Control
                      id="id_tp_medio_pago"
                      as="select"
                      name="id_tp_medio_pago"
                      value={id_tp_medio_pago}
                      onChange={e => {
                        formik.setFieldValue('id_tp_medio_pago', e.target.value);
                        setIdTpMedioPago(e.target.value);
                      }}
                      isInvalid={formik.errors.id_tp_medio_pago}
                    >
                      <option value="" disabled>
                        --Seleccione--
                      </option>
                      <option value="2">Cheque</option>
                      <option value="4">Pasarela de pago</option>
                      <option value="1">Transferencia Bancaria</option>
                      <option value="3">Efectivo</option>
                      <option value="5">Nota de Crédito</option>
                      <option value="6">Billetera movil</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group as={Row}>
                  <Form.Label column md={4} htmlFor="id_tp_medio_serie">
                    Serie
                  </Form.Label>
                  <Col md={8}>
                    <Form.Control
                      type='text'
                      id="id_tp_medio_serie"
                      name="id_tp_medio_serie"
                      value={id_tp_medio_serie}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.id_tp_medio_serie}
                      disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.id_tp_medio_serie}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column md={4} htmlFor="fecha_pago">
                    Fecha de pago
                  </Form.Label>
                  <Col md={8}>
                    <Form.Control
                      type="text"
                      id="fecha_pago"
                      defaultValue={fecha_pago}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group as={Row}>
                  <Form.Label column md={4} htmlFor="nro_documento">
                    N° Comprobante
                  </Form.Label>
                  <Col md={8}>
                    <Form.Control
                      id="nro_documento"
                      type="text"
                      name="nro_documento"
                      disabled
                      value={numeroDocumento}
                      onChange={formik.handleChange}
                      isInvalid={
                        !numeroDocumento && formik.errors.nro_documento
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.nro_documento}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column md={4} htmlFor="observaciones">
                    Observaciones
                  </Form.Label>
                  <Col md={8}>
                    <Form.Control
                      id="observaciones"
                      type="text"
                      name="observaciones"
                      value={observaciones}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Form.Group>
            
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              {mostrarCamposVariables()}
              { idTpMedioPago !== '' ? (
                <Col md={4}>
                  <Form.Group as={Row}>
                    <Form.Label column md={4} htmlFor="nro_operacion">
                      N° de operación del Banco
                    </Form.Label>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        id="nro_operacion"
                        name="nro_operacion"
                        value={nro_operacion}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.nro_operacion}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.nro_operacion}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              ) : null}
              <Col md={4}>
                <Form.Group as={Row}>
                  <Form.Label column md={4} htmlFor="id_tp_area">
                    Área
                  </Form.Label>
                  <Col md={8}>
                    <Form.Control
                      as="select"
                      id="id_tp_area"
                      name="id_tp_area"
                      value={id_tp_area}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.id_tp_area}
                    >
                      <option value="" disabled>
                        --Seleccione--
                      </option>
                      <option value="1">Consultorias</option>
                      <option value="2">Ventas</option>
                      <option value="3">Marketing</option>
                      <option value="4">Exp. del Cliente</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.id_tp_area}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              {idTpMedioPago === "4" &&(
              <Col md={4}>
              <Form.Group as={Row}>
                  <Form.Label column md={4} htmlFor="codigo">
                    Código de referencia :
                  </Form.Label>
                  <Col md={8}>
                    <Form.Control
                      id="codigo"
                      type="text" 
                      name="codigo"
                      value={codigo}
                      onChange={formik.handleChange}
                        isInvalid={formik.errors.codigo}
                    />
                     <Form.Control.Feedback type="invalid">
                      {formik.errors.codigo}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              )}
              {(idTpMedioPago === "4" || idTpMedioPago === "6") &&(
              <Col md={4}>
              <Form.Group as={Row}>
                  <Form.Label column md={4} htmlFor="codigo">
                    N° de operación del cliente
                  </Form.Label>
                  <Col md={8}>
                    <Form.Control
                      id="nro_operacion_cliente"
                      type="text" 
                      name="nro_operacion_cliente"
                      value={nro_operacion_cliente}
                      onChange={formik.handleChange}
                        isInvalid={formik.errors.nro_operacion_cliente}
                    />
                     <Form.Control.Feedback type="invalid">
                      {formik.errors.nro_operacion_cliente}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              
              )}
            </Form.Group>
            {modalidad !== "extraordinario" && (
              <Form.Group>
                <TablaPensionFraccion />
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer className="text-right">
            <Button
              variant="dark"
              className="btn-icon"
              onClick={handleCloseModal}
            >
              <FontAwesomeIcon icon={faTrash} />
              Cancelar
            </Button>
            <Button type="submit" className="btn-icon" variant="info">
              {loading ? (
                <Spinner animation="border" className="m2-1" size="sm" />
              ) : (
                <FontAwesomeIcon icon={faSave} />
              )}
              Guardar
            </Button>
          </Modal.Footer>
        </fieldset>
      </Form>
    </div>
  );
};

export default RegistroFactura;

const bancos = [
  {
    id: 1,
    nombre: "BCP",
  },
  {
    id: 2,
    nombre: "Scotiabank",
  },
  {
    id: 3,
    nombre: "Banco de la nación",
  },
  {
    id: 4,
    nombre: "Interbanck",
  },
  {
    id: 5,
    nombre: "Citibanck",
  },
  {
    id: 6,
    nombre: "BBVA",
  },
  {
    id: 9,
    nombre: "Caja Municipal (Ica)",
  },
];


const initialValues = (idPensionFraccion, todo) => {
  return {
    id: idPensionFraccion,
    nro_documento: "",
    observaciones: "",
    fecha_pago: moment(new Date()).format("L"),
    dni_ruc: todo.dni,
    id_tp_area: '',
    id_tp_medio_pago: "",
    id_tp_medio_serie: "001",
    id_tp_banco: "",
    nro_operacion: "",
    nro_operacion_cliente: "",
    codigo:""
  }
}

const validationSchema = (id_tp_medio_pago) => {
  let initialValidation = {
    nro_documento: yup.string().required("Este campo es requerido."),
    id_tp_medio_serie: yup.number().required("Este campo es requerido."),
    id_tp_area: yup.number().required('Este campo es requirido.'),
    id_tp_medio_pago: yup.number().required('Este campo es requerido.'),
    nro_operacion: yup.string().required('Este campo es requerido.'),
  }
  switch (id_tp_medio_pago) {
    case '1': //Banco
    case '2':
      initialValidation = {
        ...initialValidation,
        nro_operacion: yup.string().required("Este campo es requerido."),
        id_tp_banco: yup.number().required("Este campo es requerido."),
      }
      break;
    case '4':
      initialValidation = {
        ...initialValidation,
        codigo: yup.string().required('Este campo es requerido.'),
        nro_operacion_cliente: yup.string().required('Este campo es requerido.'),
        codigo: yup.string().required('Este campo es requerido.')

      }
      break;
    case '6':
      initialValidation = {
        ...initialValidation,
        nro_operacion_cliente: yup.string().required('Este campo es requerido.'),
      }
    break;
    default:
      break;
  }
  return initialValidation;
}
