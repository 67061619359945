const AREA_TRABAJO = {
  label: 'Área de trabajo',
  icon: 'fas fa-briefcase mr-2',
  rol: ['PRODUCTO','UXMANAGER', 'OPERACIONES', 'EXPCLIENTE'],
  subItem: [
    {
      label: "Prototipado",
      url: "/prototipado",
    }
    ,
    {
      label: "Producto",
      url: "/proyectos",
    }
    ,
    {
      label: "Sub-Producto",
      url: "/modulos",
    },
    {
      label: "Temas",
      url: "/temasPorAreas",
    }
    
    
  ],
}

const CURADURIA = {
  label: 'Curaduria',
  icon: 'fas fa-search mr-2',
  rol: ['OPERACIONES'],
  subItem: [
    {
      label: "Prototipado",
      url: "/prototipado",
    }
    ,
    {
      label: "Producto",
      url: "/proyectos",
    }
    ,
    {
      label: "Sub-Producto",
      url: "/modulos",
    },
    {
      label: "Temas",
      url: "/temasPorAreas",
    }
    
    
  ],
}



const REPORTES = {
  label: 'Reportes',
  icon: 'fas fa-chart-bar mr-2',
  rol: ['EXPCLIENTE','UXMANAGER','OPERACIONES'],
  subItem: [
    {
      label: "KPI'S",
      url: '/reporte/naranja',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    },
    {
      label: 'Funnel Cobranza',
      url: '/funnel-cobranza',
      rol: ['EXPCLIENTE', 'OPERACIONES']
    },
    {
      label: 'Afiliación',
      url: '/reporte/afiliaciones',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    },
    {
      label: 'Satisfacción',
      url: '/proyectos-satisfaccion/',
      rol: ['EXPCLIENTE','UXMANAGER','OPERACIONES']
    }
    ,
    {
      label: 'Buyer Persona',
      url: '/reporte-indicadores',
      rol: ['EXPCLIENTE','UXMANAGER','OPERACIONES']
    }
    ,
    {
      label: 'Viabilidad',
      url: '/indicadores-proyectos',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    }
    ,
    {
      label: 'Deudores',
      url: '/reporte-deudores',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    }
    ,
    {
      label: 'Diplomas',
      url: '/diplomas-emitidos',
      rol:['OPERACIONES','UXMANAGER','EXPCLIENTE']
    }
    ,
    {
      label: 'Calidad',
      url: '/reporte/calidad',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    }
  ]
}

const REPORTES_ACADEMICA = {
  label: 'Reportes',
  icon: 'fas fa-chart-bar mr-2',
  rol: ['PRODUCTO','UXMANAGER','OPERACIONES','EXPCLIENTE'],
  subItem: [
    {
      label: "KPI'S",
      url: '/reporte/naranja/academica',
      rol: ['PRODUCTO','UXMANAGER', 'OPERACIONES','EXPCLIENTE']
    },
    {
      label: 'Viabilidad',
      url: '/indicadores-proyectos',
      rol: ['OPERACIONES','UXMANAGER','PRODUCTO','EXPCLIENTE']
    },
    {
      label: 'Buyer Persona',
      url: '/reporte-indicadores',
      rol: ['PRODUCTO','UXMANAGER','OPERACIONES','EXPCLIENTE']
    }
    ,
    {
      label: 'Calidad',
      url: '/reporte/calidad',
      rol: ['PRODUCTO','UXMANAGER', 'OPERACIONES','EXPCLIENTE']
    },
    {
      label: 'Crecimiento I',
      url: '/sugerencias',
      rol: ['PRODUCTO','UXMANAGER', 'OPERACIONES','EXPCLIENTE']
    }
    ,
    {
      label: 'Crecimiento II',
      url: '/profundizar-temas-sesion',
      rol: ['PRODUCTO','UXMANAGER', 'OPERACIONES','EXPCLIENTE']
    }
  ]
}

const REPORTES_MKT = {
  label: 'Reportes',
  icon: 'fas fa-chart-bar mr-2',
  rol: ['MARKETING','OPERACIONES'],
  subItem: [

    {
      label: 'Campaña',
      url: '/reporte-campaign',
      rol: ['MARKETING', 'OPERACIONES']
    },
    {
      label: 'Viabilidad',
      url: '/indicadores-proyectos',
      rol: ['MARKETING', 'OPERACIONES']
    },
    {
      label: 'Leads',
      url: '/reporte-cantidad-leads',
      rol: ['MARKETING', 'OPERACIONES']
    }
    ,
    {
      label: 'Buyer Persona',
      url: '/reporte-indicadores',
      rol: ['MARKETING','OPERACIONES']
    }
    ,
    {
      label: "Recursos",
      url: "/status-recursos",
      rol: ['OPERACIONES','MARKETING'],
    }
    ,
    {
      label: 'Calidad',
      url: '/reporte/calidad',
      rol: ['MARKETING', 'OPERACIONES']
    }
    ,
    {
      label: 'Satisfacción',
      url: '/proyectos-satisfaccion/',
      rol: ['MARKETING', 'OPERACIONES']
    }
  ]
}

const REPORTES_VENTAS = {
  label: 'Reportes',
  icon: 'fas fa-chart-bar mr-2',
  rol: ['VENTAS','OPERACIONES','JEFE VENTAS','EXPCLIENTE'],
  subItem: [
    {
      label: 'Ventas',
      url: '/reporte-ventas-r',
      rol: ['VENTAS', 'OPERACIONES','JEFE VENTAS']
    },
    {
      label: 'Diario',
      url: '/reporte-diario-leads',
      rol: ['VENTAS','JEFE VENTAS','OPERACIONES']
    },
    {
      label: 'Mensual',
      url: '/reporte-mensual',
      rol: ['JEFE VENTAS','OPERACIONES']
    },
    {
      label: 'Medios',
      url: '/reporte-mensual-medios',
      rol: ['JEFE VENTAS','OPERACIONES']
    },
    {
      label: "Ranking",
      icon: "fas fa-line-chart mr-2",
      rol: ['EXPCLIENTE', 'VENTAS', 'OPERACIONES','JEFE VENTAS'],
      url: '/ventas/ranking'
    }
    ,
    {
      label: 'Tratos',
      url: '/tratos-report',
      rol: ['EXPCLIENTE', 'OPERACIONES']
    },
    ,
    {
      label: 'Llamadas',
      url: '/reporte-llamadas',
      rol: ['VENTAS','JEFE VENTAS','OPERACIONES']
    },
    {
      label: 'Viabilidad',
      url: '/indicadores-proyectos',
      rol: ['VENTAS','JEFE VENTAS','OPERACIONES']
    },
    {
      label: 'Buyer Persona',
      url: '/reporte-indicadores',
      rol: ['VENTAS','JEFE VENTAS','OPERACIONES']
    }
    ,
    {
      label: 'Leads',
      url: '/reporte-cantidad-leads',
      rol: ['JEFE VENTAS','OPERACIONES']
    }
    ,
    {
      label: "Recursos",
      url: "/status-recursos",
      rol: ['JEFE VENTAS','OPERACIONES']
    }
    ,
    {
      label: 'Calidad',
      url: '/reporte/calidad',
      rol: ['JEFE VENTAS','OPERACIONES']
    }
    ,
    {
      label: 'Satisfacción',
      url: '/proyectos-satisfaccion/',
      rol: ['JEFE VENTAS','OPERACIONES']
    }
  ]
}


const COMENTARIOS_VIDEO = {
  label: 'Ver Comentarios',
  icon: 'fas fa-comment mr-2',
  rol: ['DOCENTE', 'OPERACIONES'],
  url: '/comentarios-videos'
}

const TEMAS_FORO = {
  label: 'Ver Temas',
  icon: 'fas fa-list mr-2',
  rol: ['DOCENTE', 'OPERACIONES'],
  url: '/foro-producto'
}

const RECURSOS_DOCENTE = {
  label: 'Subir Materiales',
  icon: 'fas fa-upload mr-2',
  rol: ['DOCENTE', 'OPERACIONES'],
  url: "/recursos",
}

const PROVEEDORES_M = {
  label: 'Proveedores',
  icon: 'fas fa-briefcase mr-2',
  rol: ['PRODUCTO', 'OPERACIONES','EXPCLIENTE','UXMANAGER','HRBP'],
  url: "/proveedores-m",
}

const CONTRATOS_PROV = {
  label: 'Contratos',
  icon: 'fas fa-list mr-2',
  rol: ['PRODUCTO', 'OPERACIONES','EXPCLIENTE','UXMANAGER','HRBP'],
  url: "/contratos-proveedores",
}

const BOLSA_DE_TRABAJO = {
  label: 'Ofertas laborales',
  icon: 'fas fa-edit mr-2',
  rol: ['RECLUTADOR', 'OPERACIONES','EXPCLIENTE'],
  url: '/bolsa-de-trabajo'
}

const POPUP = {
  label: 'Mensaje Intranet',
  icon: 'fas fa-commenting mr-2',
  rol: ['EXPCLIENTE', 'OPERACIONES'],
  subItem: [
    {
      label: 'POP-UP Intranet',
      url: '/popup/intranet',
      rol: ['EXPCLIENTE', 'OPERACIONES']
    }
  ]
}
const CONTRATOS = {
  label: 'Contratos',
  icon: 'fas fa-desktop mr-2',
  rol: ['FINANZAS', 'OPERACIONES'],
  url: '/contratos'
}

const AREA_TRABAJO_HRBP = {
  label: 'Área de trabajo',
  icon: 'fas fa-briefcase mr-2',
  rol: ['HRBP','OPERACIONES'],
  subItem: [
    {
      label: 'Comunicados',
      url: '/listar-comunicados',
    }
    ,
    {
      label: 'Trabajadores',
      url: '/trabajadores',
    }
      ,

    {
      label: 'Bolsa de Trabajo',
      url: '/bolsa-de-trabajo'
    }
    ,
  ]
}

const MKT_OP = {
  label: 'MKT',
  icon: 'fas fa-briefcase mr-2',
  rol: ['MARKETING','OPERACIONES','JEFE VENTAS'],
  subItem: [
    {
      label: 'Leads',
      icon: 'fas fa-list mr-2',
      rol: ['MARKETING', 'OPERACIONES'],
      url: "/leads"
    }
    ,
    {
      label: "Campañas",
      url: '/campaigns',
      rol: ['OPERACIONES','MARKETING','JEFE VENTAS']
    }
    ,
    {
      label: "Prototipado",
      url: "/prototipado",
      rol: ['OPERACIONES','MARKETING','JEFE VENTAS'],
    }
    ,
    {
      label: 'Contactos',
      icon: 'fas fa-users mr-2',
      rol: ['MARKETING', 'OPERACIONES'],
      url: "/contactos"
    }
    ,
    {
      label: 'Tratos',
      icon: 'fas fa-folder-open mr-2',
      rol: ['MARKETING', 'OPERACIONES'],
      url: "/tratos"
    },
  ]
}


const AREA_TRABAJO_MKT = {
  label: 'Área de trabajo',
  icon: 'fas fa-briefcase mr-2',
  rol: ['MARKETING','OPERACIONES','JEFE VENTAS'],
  subItem: [
    {
      label: "Prototipado",
      url: "/prototipado",
      rol: ['OPERACIONES','MARKETING','JEFE VENTAS'],
    }
    ,

    {
      label: "Campañas",
      url: '/campaigns',
      rol: ['OPERACIONES','MARKETING','JEFE VENTAS']
    }
    ,
    {
      label: 'Leads',
      icon: 'fas fa-list mr-2',
      rol: ['MARKETING', 'OPERACIONES','JEFE VENTAS'],
      url: "/leads"
    }
    ,
    {
      label: 'Tratos',
      icon: 'fas fa-folder-open mr-2',
      rol: ['MARKETING', 'OPERACIONES'],
      url: "/tratos"
    },
    {
      label: "Producto",
      url: "/proyectos",
      rol: ['OPERACIONES']
    }
    ,
  ]
}

const AREA_TRABAJO_FINANZAS= {
  label: 'Área de trabajo',
  icon: 'fas fa-briefcase mr-2',
  rol: ['OPERACIONES','JEFE VENTAS','FINANZAS'],
  subItem: [
    {
      label: "Trabajadores",
      url: "/trabajadores",
      rol: ['OPERACIONES','JEFE VENTAS','FINANZAS'],
    }
    ,
    {
      label: "Enlaces",
      url: "/enlaces-finanzas",
      rol: ['OPERACIONES','JEFE VENTAS','FINANZAS'],
    }
    ,
    {
      label: "Premios",
      url: "/premios",
      rol: ['OPERACIONES','JEFE VENTAS','FINANZAS','EXPCLIENTE'],
    }
    ,

  ]
}


const AREA_TRABAJO_VENTAS = {
  label: 'Área de trabajo',
  icon: 'fas fa-briefcase mr-2',
  rol: ['JEFE VENTAS','VENTAS','OPERACIONES', 'UXMANAGER'],
  subItem: [
    {
      label: 'Leads',
      icon: 'fas fa-list mr-2',
      rol: ['JEFE VENTAS','VENTAS','OPERACIONES'],
      url: "/leads"
    }
    ,
    {
      label: 'Contactos',
      icon: 'fas fa-users mr-2',
      rol: ['VENTAS','JEFE VENTAS','OPERACIONES'],
      url: "/contactos"
    }
    ,
    {
      label: 'Tratos',
      icon: 'fas fa-folder-open mr-2',
      rol: ['VENTAS','JEFE VENTAS','OPERACIONES'],
      url: "/tratos"
    },
    {
      label: "Campañas",
      url: '/campaigns',
      rol: ['OPERACIONES','JEFE VENTAS']
    }
    ,
    {
      label: "Contratos",
      url: "/contratos",
      rol: ['VENTAS','JEFE VENTAS','OPERACIONES'],
    }
  ]
}


const PERSONAS = {
  label: 'Personas',
  icon: 'fas fa-users mr-2',
  rol: ['OPERACIONES'],
  subItem: [
    {
      label: 'Naranja',
      url: '/reporte/naranja',
      rol: ['OPERACIONES']
    },
    {
      label: 'Afiliación',
      url: '/reporte/afiliaciones',
      rol: ['OPERACIONES']
    },
    {
      label: 'Satisfacción',
      url: '/proyectos-satisfaccion/',
      rol: ['OPERACIONES']
    },
    {
      label: 'Progreso',
      url: '/reporte/seguimiento',
      rol: ['OPERACIONES']
    },
    {
      label: 'Crecimiento I',
      url: '/sugerencias',
      rol: ['OPERACIONES']
    }
    ,
    {
      label: 'Crecimiento II',
      url: '/profundizar-temas-sesion',
      rol: ['OPERACIONES']
    },
    {
      label: 'SOS',
      url: '/reporte/desertados',
      rol: ['OPERACIONES']
    }
    ,
    {
      label: "Producto",
      url: "/proyectos",
      rol: ['OPERACIONES']
    }
    ,
    {
      label: "Docentes",
      url: "/profesores",
      rol: ['OPERACIONES']
    }
  ]
}

const AREA_TRABAJO_PV = {
  label: 'Área de trabajo',
  icon: 'fas fa-briefcase mr-2',
  rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES'],
  subItem: [
    {
      label: 'Progreso',
      url: '/reporte/seguimiento',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    },
    {
      label: 'Crecimiento I',
      url: '/sugerencias',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    }
    ,
    {
      label: 'Crecimiento II',
      url: '/profundizar-temas-sesion',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    },
    {
      label: 'SOS',
      url: '/reporte/desertados',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    },
    {
      label: "Discentes",
      url: "/matriculas",
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES'],
    },
    {
      label: 'PopUp',
      url: '/popup/intranet',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    }
    ,
    {
      label: "WhatsApp",
      url: "/grupos-whatsapp",
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    },
    {
      label: "Producto",
      url: "/proyectos",
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    }
    ,
    {
      label: "Docentes",
      url: "/profesores",
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    },
    {
      label: 'Ver Comentarios',
      rol: ['EXPCLIENTE', 'OPERACIONES'],
      url: '/comentarios-videos'
    }
  ]
}
const PROYECTOS = {
  label: "Producto",
  icon: "fas fa-desktop mr-2",
  rol: ['EXPCLIENTE', 'PRODUCTO', 'OPERACIONES'],
  url: '/proyectos'
}

const RANKINGVENTAS = {
  label: "Ranking",
  icon: "fas fa-line-chart mr-2",
  rol: ['EXPCLIENTE', 'VENTAS', 'OPERACIONES','JEFE VENTAS'],
  url: '/ventas/ranking'
}
const MODULOS = {
  label: "Sub-Producto",
  icon: "fas fa-desktop mr-2",
  rol: ['PRODUCTO', 'OPERACIONES'],
  url: '/modulos'
}

const DISCENTES =  {
  label: "Matrículas",
  icon: "fas fa-user mr-2",
  rol: ['PRODUCTO', 'OPERACIONES','FINANZAS'],
  subItem: [
    {
      label: "Nuevo Discente",
      url: '/estudiantes',
      rol: ['PRODUCTO', 'OPERACIONES','FINANZAS']
    },
    {
      label: "Nueva matrícula",
      url: "/nueva-matricula",
      rol: ['PRODUCTO', 'OPERACIONES','FINANZAS'],
    },
    {
      label: "Registro de Matrículas",
      url: "/matriculas",
      rol: ['PRODUCTO', 'OPERACIONES','FINANZAS'],
    }
  ],
}

const GESTION_CAMP =  {
  label: "Gestion de Campañas",
  icon: "fas fa-bullhorn mr-2",
  rol: ['OPERACIONES','MARKETING'],
  subItem: [
    {
      label: "Campañas",
      url: '/campaigns',
      rol: ['OPERACIONES','MARKETING']
    },
    {
      label: "Recursos",
      url: "/recursos-campaign",
      rol: ['OPERACIONES','MARKETING'],
    },
    {
      label: "Anuncios",
      url: "/anuncios-campaign",
      rol: ['OPERACIONES','MARKETING'],
    }
    ,
    {
      label: "Webinars",
      url: "/webinars-ventas",
      rol: ['OPERACIONES','MARKETING'],
    }
    ,
    {
      label: "Prototipado",
      url: "/prototipado",
      rol: ['OPERACIONES','MARKETING'],
    }
  ],
}

const DOCENTES =  {
  label: "Docentes",
  icon: "fas fa-chalkboard-teacher mr-2",
  rol: ['PRODUCTO', 'EXPCLIENTE','UXMANAGER', 'OPERACIONES'],
  subItem: [
    {
      label: "Docentes",
      url: '/profesores',
      rol: ['PRODUCTO', 'EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    },
    {
      label: "Desempeño",
      url: "/profesores-report",
      rol: ['PRODUCTO', 'EXPCLIENTE','UXMANAGER', 'OPERACIONES'],
    },
  ],
}


/*const DOCENTES = {
  label: 'Docentes',
  icon: 'fas fa-chalkboard-teacher mr-2',
  rol: ['PRODUCTO', 'EXPCLIENTE','UXMANAGER', 'OPERACIONES'],
  subitem: [
    {
      label: "Docente",
      url: "/profesores",
      rol: ['PRODUCTO', 'EXPCLIENTE','UXMANAGER', 'OPERACIONES'],
    },
    {
      label: "Ranking",
      url: "/profesores",
      rol: ['PRODUCTO', 'EXPCLIENTE','UXMANAGER', 'OPERACIONES'],
    }
  ],
  //url: "/profesores"
}*/

const CAMPAIGNS = {
  label: 'Campañas',
  icon: 'fas fa-book mr-2',
  rol: ['MARKETING', 'OPERACIONES'],
  url: "/campaigns"
}

const LEADS = {
  label: 'Leads',
  icon: 'fas fa-list mr-2',
  rol: ['VENTAS', 'MARKETING', 'OPERACIONES','JEFE VENTAS'],
  url: "/leads"
}
const MAILEDITOR = {
  label: 'Emailing',
  icon: 'fas fa-envelope fa-x1 mr-2',
  rol: ['VENTAS', 'MARKETING', 'OPERACIONES','JEFE VENTAS'],
  subItem: [
    {
      label: "Crear plantilla",
      url: "/email-editor",
    },
    {
      label: 'Editar Plantilla',
      url: "/listar-plantillas",
    }
  ],
}
const CONTACTOS = {
  label: 'Contactos',
  icon: 'fas fa-users mr-2',
  rol: ['VENTAS', 'MARKETING', 'OPERACIONES','JEFE VENTAS'],
  url: "/contactos"
}
const TRATOS = {
  label: 'Tratos',
  icon: 'fas fa-folder-open mr-2',
  rol: ['VENTAS', 'MARKETING', 'OPERACIONES','JEFE VENTAS'],
  url: "/tratos"
}
const WEBINARS = {
  label: 'Webinars',
  icon: 'fas fa-video-camera mr-2',
  rol: ['OPERACIONES','JEFE VENTAS'],
  url: "/webinars-ventas"
}
const RECURSOS_CAMP = {
  label: 'Recursos',
  icon: 'fas fa-file-code-o mr-2',
  rol: ['OPERACIONES','JEFE VENTAS','VENTAS'],
  url: "/recursos-campaign"
}

  
const ENLACES = {
  label: 'Enlaces',
  icon: 'fas fa-link mr-2',
  rol: ['FINANZAS', 'OPERACIONES','JEFE VENTAS'],
  url: "/enlaces-finanzas"
}

const ASISTENCIA = {
  label: 'Asistencia Discentes',
  icon: 'fas fa-calendar-check mr-2',
  rol: ['EXPCLIENTE', 'OPERACIONES', 'TUTORIA'],
  subItem: [
    {
      label: "Registrar Asistencia",
      url: "/registro-asistencia",
    },
    {
      label: 'Grupos Academicos',
      url: "/grupos-academicos",
    }
  ],
}

const RECURSOS = {
  label: 'Recursos',
  icon: 'fas fa-folder-plus mr-2',
  //style: {fontSize: '19px', marginRight: '5px'},
  rol: ['PRODUCTO', 'OPERACIONES','UXMANAGER','DOCENTE','EDITOR AUDIOVISUAL', 'EXPCLIENTE'],
  subItem: [
    {
      label: "Los materiales",
      url: "/recursos",
      rol:['OPERACIONES','UXMANAGER','PRODUCTO','DOCENTE','EDITOR AUDIOVISUAL','EXPCLIENTE']
    },
    {
      label: 'La Actividad',
      url: '/tareas',
      rol:['OPERACIONES','UXMANAGER','PRODUCTO','EXPCLIENTE']
    },
    {
      label: 'El Quiz',
      url: '/examenes',
      rol:['OPERACIONES','UXMANAGER','PRODUCTO','EXPCLIENTE']
    }
  ],
}

const DIPLOMAS = {
  label: 'Diplomas',
  icon: 'fas fa-folder-plus mr-2',
  //style: {fontSize: '19px', marginRight: '5px'},
  rol: ['PRODUCTO', 'OPERACIONES','UXMANAGER','EXPCLIENTE'],
  subItem: [
    {
      label: "Plantillas",
      url: "/bases-certificados",
      rol:['OPERACIONES','UXMANAGER','PRODUCTO','EXPCLIENTE']
    },
    {
      label: "Emisión",
      url: "/emitir-aptos",
      rol:['OPERACIONES','UXMANAGER','PRODUCTO','EXPCLIENTE']
    },
    {
      label: 'Status',
      url: '/diplomas-emitidos',
      rol:['OPERACIONES','UXMANAGER','PRODUCTO','EXPCLIENTE']
    },
    {
      label: 'Constancia',
      url: '/constancia',
      rol:['EXPCLIENTE', 'OPERACIONES']
    },
    
  ],
}


const TUTORIA = {
  label: 'Experiencia',
  icon: 'fas fa-chalkboard-teacher mr-2',
  rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES'],
  subItem: [
    {
      label: 'Gestores',
      url: '/tutores',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    },
    {
      label: 'Mensajes',
      url: '/mensajeria',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    }
    ,
    {
      label: 'Asistencias',
      url: "/registro-asistencia",
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    }
    ,
    {
      label: 'Grupos',
      url: "/grupos-academicos",
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    }
  ]
}

const CONTROL_PAGOS = {
  label: 'Pagos',
  icon: 'fas fa-balance-scale mr-2',
  rol: ['PRODUCTO', 'OPERACIONES', 'FINANZAS'],
  url: '/pagos'

}

const FINANZAS = {
  label: 'Reportes',
  icon: 'fas fa-table mr-2',
  rol: ['PRODUCTO', 'OPERACIONES', 'FINANZAS'],
  subItem: [
    {
      label: 'Ingresos',
      url: '/reporte-ingresos'
    },
    {
      label: 'Cargos y descuentos',
      url: '/reporte-cargosdescuentos',
      rol: ['OPERACIONES','FINANZAS']
    } ,
    {
      label: 'Deudores',
      url: '/reporte-deudores'
    }
    ,
    {
      label: 'Viabilidad',
      url: '/indicadores-proyectos',
      rol: ['OPERACIONES','FINANZAS']
    },
    {
      label: 'Ventas',
      url: '/reporte-ventas-r',
      rol: ['VENTAS', 'OPERACIONES','JEFE VENTAS']
    }
  ]
}


const FINANZAS_OP = {
  label: 'Finanzas',
  icon: 'fas fa-table mr-2',
  rol: ['OPERACIONES'],
  subItem: [
    {
      label: 'Tabajadores',
      icon: 'fas fa-users mr-2',
      rol: ['OPERACIONES'],
      url: '/trabajadores'
    },
    {
      label: 'Registro de Pagos',
      icon: 'fas fa-balance-scale mr-2',
      rol: ['PRODUCTO', 'OPERACIONES', 'FINANZAS'],
      url: '/pagos'
    
    },    
    {
      label: "Matrículas",
      url: "/matriculas",
      rol: ['OPERACIONES'],
    },
    {
      label: 'Ingresos',
      url: '/reporte-ingresos',
      rol: ['OPERACIONES'],
    },
    {
      label: 'Deudores',
      url: '/reporte-deudores',
      rol: ['OPERACIONES'],
    }
    ,
    {
      label: 'Viabilidad',
      url: '/indicadores-proyectos',
      rol: ['OPERACIONES'],
    },
  ]
}



const MENSAJERIA = {
  label: 'Mensajeria con Discentes',
  icon: 'fas fa-envelope mr-2',
  rol: ['EXPCLIENTE','OPERACIONES'],
  subItem: [
    {
      label: 'Mensajes',
      url: '/mensajeria'
    }
  ]
}

const WEBCHECKIN = {
  label: 'WebCheckin',
  icon: 'fas fa-calendar-check',
  rol: ['PRODUCTO', 'EXPCLIENTE', 'OPERACIONES', 'FINANZAS'],
  url: '/webcheckin'
}


const TRABAJADORES = {
  label: 'Tabajadores',
  icon: 'fas fa-users mr-2',
  rol: ['OPERACIONES','FINANZAS'],
  url: '/trabajadores'
}
const COMUNICADOS = {
  label: 'Comunicados',
  icon: 'fas fa-comment mr-2',
  rol: ['OPERACIONES'],
  url: '/listar-comunicados'
}
const APROBARCERT = {

    label: 'Diplomas',
    icon: 'fas fa-edit mr-2',
    url: '/aprobar-diplomas',
    rol:['OPERACIONES']
  
}
const BOLETASDEPAGO = {
  label: 'Boletas de Pago',
  icon: 'fas fa-file mr-2',
  rol: ['EXPCLIENTE','OPERACIONES', 'PRODUCTO','UXMANAGER', 'TUTORIA', 'FINANZAS','MARKETING','DOCENTE','VENTAS','JEFE VENTAS','RECLUTADOR','TI','HRBP'],
  url: '/boletas-de-pago'
}
const ENLACESFORM = {
  label: 'Contratos',
  icon: 'fas fa-file mr-2',
  rol: ['EXPCLIENTE','OPERACIONES','JEFE VENTAS'],
  subItem: [
    {
      label: "Firmados",
      url: "/contratos",
    },
    {
      label: "Nuevo",
      url: "/contratos/enlaces",
    },
  ],
}


const EQUIPOVENTAS = {
  label: 'Equipo',
  icon: 'fas fa-users mr-2',
  rol: ['OPERACIONES','JEFE VENTAS'],
  subItem: [
    {
      label: "Configuración",
      url: "/equipo-ventas",
    },
    
  ],
}

const EVENTOS = {
  label: "Eventos",
  icon: "fas fa-graduation-cap mr-2",
  rol: ['TUTORIA','EXPCLIENTE'],
  subItem: [
    {
      label: "Eventos",
      url: "/tutor/eventos",
    },
  ],
}

export const SIDEBAR_PRINCIPAL = [
 
/* {
    title: 'WebCheckin',
    rol: ['OPERACIONES'],
    rutas: [
      WEBCHECKIN,
    ]
  },
*/
  {
    title: 'MARKETING',
    icon: "fas fa-bullhorn mr-2",
    rol: ['MARKETING','OPERACIONES'],
    rutas: [
      AREA_TRABAJO_MKT,
      MAILEDITOR,
      REPORTES_MKT
    ]
  },
  {
    title: 'COMERCIAL',
    icon: "fas fa-area-chart mr-2",
    rol: ['VENTAS','EXPCLIENTE','JEFE VENTAS','OPERACIONES', 'UXMANAGER'],
    rutas: [
      AREA_TRABAJO_VENTAS,
      ENLACESFORM,
      RECURSOS_CAMP,
      EQUIPOVENTAS,
      REPORTES_VENTAS
    ]
  },
    
  {
    title: 'PERSONAS',
    icon: "fas fa-users mr-2",
    rol: ['EXPCLIENTE','UXMANAGER','OPERACIONES'],
    rutas: [
      AREA_TRABAJO_PV,
      BOLSA_DE_TRABAJO,
      TUTORIA,
      REPORTES,
    ]
  },
  {
    title: 'CURADURíA',
    icon: "fas fa-archive mr-2",
    rol: ['PRODUCTO','UXMANAGER','EDITOR AUDIOVISUAL','OPERACIONES','EXPCLIENTE'],
    rutas: [
      AREA_TRABAJO,
      DIPLOMAS,
      DOCENTES,
      RECURSOS,
      REPORTES_ACADEMICA
    ]
  },
  {
    title: 'OPERACIONES',
    icon: "fas fa-book mr-2",
    rol: ['OPERACIONES'],
    rutas: [
      APROBARCERT,
    ]
  },
  
  {
    title: 'FINANZAS',
    icon: "fas fa-line-chart mr-2",
    rol: ['FINANZAS','OPERACIONES'],
    rutas: [
      AREA_TRABAJO_FINANZAS,
      CONTRATOS,
      DISCENTES,
      CONTROL_PAGOS,
      FINANZAS,
    ]
  },
  {
    title: 'HHRR',
    icon: "fas fa-users mr-2",
    rol: ['HRBP','OPERACIONES'],
    rutas: [
     AREA_TRABAJO_HRBP
    ]
  },
  {
    title: 'DOCUMENTOS',
    icon: "fas fa-file mr-2",
    rol: ['EXPCLIENTE', 'PRODUCTO','UXMANAGER', 'TUTORIA', 'FINANZAS','MARKETING','VENTAS','JEFE VENTAS','RECLUTADOR','TI','HRBP','OPERACIONES'],
    rutas: [
      BOLETASDEPAGO,
    ]
  },
  {
    title: 'COMENTARIOS',
    icon: "fas fa-comments mr-2",
    rol: ['DOCENTE'],
    rutas: [
      COMENTARIOS_VIDEO,
    ]
  },
  {
    title: 'RECURSOS',
    icon: "fas fa-folder mr-2",
    rol: ['DOCENTE'],
    rutas: [
      RECURSOS_DOCENTE,
    ]
  },
  {
    title: 'FORO',
    icon: "fas fa-commenting mr-2",
    rol: ['DOCENTE'],
    rutas: [
      TEMAS_FORO,
    ]
  },
  {
    title: 'BOLSA DE TRABAJO',
    icon: "fas fa-list mr-2",
    rol: ['RECLUTADOR'],
    rutas: [
      BOLSA_DE_TRABAJO,
    ]
  },

  {
    title: 'PROVEEDORES',
    icon: "fas fa-building mr-2",
    rol: ['PRODUCTO','EXPCLIENTE','UXMANAGER','HRBP','OPERACIONES','MARKETING'],
    rutas: [
      PROVEEDORES_M,
      CONTRATOS_PROV,
    ]
  },

  {
    title: 'TUTORÍA',
    icon: "fas chalkboard-teacher mr-2",
    rol: ['TUTORIA'],
    rutas: [
      EVENTOS,
      ASISTENCIA,
      MENSAJERIA
    ]
  }, 

]

// export const SIDEBAR_ADMIN = [
//   AREA_TRABAJO,
//   REPORTES,
//   PROYECTOS,
//   MATRICULA,
//   DOCENTES, 
//   ASISTENCIA,
//   RECURSOS,
//   DISCENTES,
//   TUTORIA,
//   FINANZAS,
//   MENSAJERIA,
//   TRABAJADORES
// ];


// export const SIDEBAR_ACADEMICA = [
//   AREA_TRABAJO,
//   REPORTES_ACANDEMICA,
//   PROYECTOS,
//   DOCENTES,
//   DISCENTES,
//   RECURSOS,
//   MENSAJERIA
// ]

// export const SIDEBAR_POSVENTA = [
//   REPORTES,
//   MENSAJERIA,
//   ASISTENCIA,
//   TUTORIA,
//   PROYECTOS,
//   DOCENTES,
//   DISCENTES,
//   FINANZAS
// ]

// export const SIDEBAR_CONTABILIDAD = [
//   FINANZAS
// ]

// export const SIDEBAR_TUTORIA = [
//   EVENTOS,
//   ASISTENCIA
// ]