import React, { useEffect, useState } from 'react';
import Layout from '../../../../app/Layout';
import HeaderContent from '../../General/HeaderContent';
import Breadcrumb from '../../General/Breadcrumb';
import { Tab, Tabs } from 'react-bootstrap';
import FormNivelAcademicoProyecto from '../../../components/ExaminarProyecto/FormNivelAcademicoProyecto';
import CardCapacitacionProyecto from '../../../components/ListaCardControlCapacitaciones/CardControlCapacitaionProyecto';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faMoneyBillAlt, faListAlt, faSchool, faCogs } from '@fortawesome/free-solid-svg-icons';
import FormHorarioProyecto from '../../../components/ExaminarProyecto/FormHorarioProyecto';
import FinanzasProyectos from '../../../components/ExaminarProyecto/FinanzasProyectos/FinanzasProyectos';
import { useParams } from 'react-router-dom';
import ModalBasic from '../../../components/Modals/ModalBasic';
//Redux
import { useSelector, useDispatch } from 'react-redux';
import { listarProyecto } from '../../../../actions/cursosActions';
import SpinnerLoading from '../../../components/Spinners/SpinnerLoading';
import Fatal from '../../../components/MessageAlert/Fatal';
import ModulosProyectos from '../../../components/ExaminarProyecto/ModulosProyectosAsociados';
import { obtenerInfoControl, obtenerTodosCapacitacionesControl } from '../../../../actions/controlCapacitacionesActions';
import { borrarPlan } from '../../../../reducers/CursosReducer';

const ExaminarProyecto = () => {

  const { loading, error, proyecto } = useSelector(state => state.cursos);
  const dispatch = useDispatch();
  const { idProyecto } = useParams();

  const mostrarContainerTabs = () => {
    if(loading) return <SpinnerLoading />
    if(error) return <Fatal message={error} />
    if (proyecto) return <ContenedorTabs proyecto={proyecto} id_proyecto = {idProyecto} capacitacionesTodos={capacitacionesTodos} />
    
  }

  useEffect(() => {
    dispatch(listarProyecto(idProyecto))
  }, [])

  const { todos: capacitacionesTodos} = useSelector(state => state.control_capacitacion.capacitaciones)

  useEffect(() => {
    if (!capacitacionesTodos.length) {
      dispatch( obtenerTodosCapacitacionesControl() )
    }
  }, [])

  return ( 
    <Layout>
      <div className="ExaminarProyecto">
        <div className="ExaminarProyecto__header">
          <HeaderContent title='Examinar Producto' />
          <Breadcrumb
            link={[
              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
              { url: "/proyectos", nombre: "Productos" },
              { url: "", nombre: "Examinar Producto" },
            ]}
          />
        </div>
        <div className="ExaminarProyecto__body">
          {mostrarContainerTabs()}
        </div>
      </div>
    </Layout>
  );
}
 
export default ExaminarProyecto;


const ContenedorTabs = ({ proyecto, id_proyecto, capacitacionesTodos}) => {

  const [show, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titleModal, setTitleModal] = useState('');
  const onShowModal = (content, title) => {
    setContentModal(content);
    setTitleModal(title);
    setShowModal(true);
  }
  const onCloseModal = () =>{ 
    setShowModal(false)
    borrarPlan();
  };

  return (
    <>
      <ModalBasic
        title={titleModal}
        show={show}
        updateShowModal={setShowModal}
      >
        {contentModal}
      </ModalBasic>
      <Tabs
        id="controlador-seccion-proyecto"
        defaultActiveKey={'panel-control'}
      >
        <Tab
          eventKey="panel-control"
          title={
            <div className='text-center'>
              <FontAwesomeIcon icon={faCogs} />
              <p className='mt-2'>Panel de Control</p>
            </div>
          }
        >
         { capacitacionesTodos.length ? <CardCapacitacionProyecto id_proyecto_={id_proyecto} /> : 'No hay datos'}
        </Tab>
        <Tab
          eventKey="academico"
          title={
            <div className='text-center'>
              <FontAwesomeIcon icon={faSchool} />
              <p className='mt-2'>Nivel Académico</p>
            </div>
          }
        >
          <FormNivelAcademicoProyecto academico={proyecto.academico} />
        </Tab>
        <Tab
          eventKey="horario"
          title={
            <div className='text-center'>
              <FontAwesomeIcon icon={faCalendarAlt} />
              <p className='mt-2'>Horario</p>
            </div>
          }
        >
          <FormHorarioProyecto datosHorario={proyecto.horario} />
        </Tab>
        <Tab
          eventKey="finanzas"
          title={
            <div className='text-center'>
              <FontAwesomeIcon icon={faMoneyBillAlt} />
              <p className='mt-2'>Finanzas</p>
            </div>
          }
        >
          <FinanzasProyectos 
            onShowModal={onShowModal}
            onCloseModal={onCloseModal}
          />
        </Tab>
        <Tab
          eventKey="modulos"
          title={
            <div className='text-center'>
              <FontAwesomeIcon icon={faListAlt} />
              <p className='mt-2'>Sub-Producto</p>
            </div>
          }
        >
          <ModulosProyectos
            modulos={proyecto.modulos}
            onShowModal={onShowModal}
            onCloseModal={onCloseModal}
          />
        </Tab>
      </Tabs>
    </>
  )
}
