import authAxios from '../config/authAxios';
import { mostrarErrorApi } from '../helpers/functions';
import { avisoCorrecto, avisoError } from '../view/components/AvisosMensajes/MensajesSwalf';
import { matriculasTypes } from './types';
import Swal from 'sweetalert2'

export const obtenerMatriculas = datos => {
  return async dispatch => {
    dispatch({
      type: matriculasTypes.OBTENER_TODAS_MATRICULAS
    })
    try {
      const { idEvento, estado } = datos;
      const respuesta = await authAxios.get(`/admin/reporte/matriculas?idEvento=${idEvento}&estado=${estado}`);

      dispatch({
        type: matriculasTypes.OBTENER_TODAS_MATRICULAS_EXITO,
        payload: respuesta.data.data
      })
    } catch (error) {
      console.log(error)
      dispatch({
        type: matriculasTypes.OBTENER_TODAS_MATRICULAS_ERROR,
        payload: error.response.data.msg
      })
    }
  }
}

export const limpiarResultadoMatriculas = () => {
  return dispatch => {
    dispatch({
      type: matriculasTypes.LIMPIAR_RESULTADO_MATRICULAS
    })
  }
}

export const nuevaMatricula = datos => {
  return async dispatch => {
    dispatch({
      type: matriculasTypes.NUEVA_MATRICULA
    })
    try {
      await authAxios.post('/admin/matricula', datos);
      dispatch({
        type: matriculasTypes.NUEVA_MATRICULA_EXITO
      })
      avisoCorrecto('Se creo una nueva matrícula correctamente');
    } catch (error) {
      console.log(error);
      avisoError(error.response.data.msg);
      dispatch({
        type: matriculasTypes.NUEVA_MATRICULA_ERROR,
        payload: error.response.data.msg
      })
    }
  }
}

export const obtenerMatriculaDiscente = idMatricula => {
  return async dispatch => {
    dispatch({
      type: matriculasTypes.OBTENER_MATRICULA_DISCENTE
    })
    try {
      const respuesta = await authAxios.get(`admin/matricula/${idMatricula}`);
      dispatch({
        type: matriculasTypes.OBTENER_MATRICULA_DISCENTE_EXITO,
        payload: respuesta.data.data
      })
    } catch (error) {
      console.log(error);
      mostrarErrorApi(error)
      dispatch({
        type: matriculasTypes.OBTENER_MATRICULA_DISCENTE_ERROR,
        payload: error.response.data.msg
      })
    }
  }
}

export const editarEstadoMatriculaDiscente = (idMatricula, idEstado,pago_r) => {
  return async dispatch => {
    dispatch({
      type: matriculasTypes.EDITAR_ESTADO_MATRICULA_DISCENTE
    })
    try {
      await authAxios.put(`/admin/matricula/${idMatricula}/estado/${idEstado}/pago/${pago_r}`);
      dispatch({
        type: matriculasTypes.EDITAR_ESTADO_MATRICULA_DISCENTE_EXITO
      })
      avisoCorrecto('Se edito correctamente el estado.');
    } catch (error) {
      console.log(error);
      mostrarErrorApi(error);
      dispatch({
        type: matriculasTypes.EDITAR_ESTADO_MATRICULA_DISCENTE_ERROR,
        payload: error.response.data.data.msg
      })
    }
  }
}

export const eliminarMatricula = (idMatricula) => {
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
      });
      swalWithBootstrapButtons
      .fire({
          title: '¿Estas seguro?',
          text: "No se podrá recuperar los datos de esta matrícula",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async function() {
          try {
            await authAxios.delete(`/admin/matricula/${idMatricula}`)
            dispatch({
                type: matriculasTypes.ELIMINAR_MATRICULA_EXITO,
                payload: {
                  idMatricula,
                }
            })
            return true;
          } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response.data.msg
            })
          }},
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
          //mensaje que se cambio la contraseña satisfactoriamente
          if (result.value === true) {
          swalWithBootstrapButtons.fire(
              "Eliminado!",
              "Matricula se eliminó correctamente",
              "success"
          );
          }
      });
  }

}

export const comprobarPlan = (data)=> { 
  const {
    dni: dni,
    id_proyecto: idproyecto} = data;

  return async dispatch =>{
    try{
      const respuesta = await authAxios.get(`/admin/comprobarPlan/${dni}/${idproyecto}`)
      return respuesta.data.data
    }catch(error){
      console.log(error)
      return {mensaje: false, textMensaje: error}
    }
  }
}

