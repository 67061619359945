import { temasTypes } from "../actions/types";

const initialState = {
  temas:null,
  show_formulario: false,
  tema_actual:null,
  video_actual:null,
  loading:true
}

export default function(state = initialState, action) {
  switch (action.type) {
    case temasTypes.SHOW_FORM:
      return {
        ...state,
        show_formulario: !state.show_formulario
      }
    case temasTypes.OBTENER_TEMAS_EXITO:
      return{
          ...state,
          temas:action.payload.temas
      }
    case temasTypes.OBTENER_TEMA_EXITO:
      return{
          ...state,
          tema_actual:action.payload.tema
      }
    case temasTypes.OBTENER_VIDEO_EXITO:
      return{
          ...state,
          video_actual:action.payload.video
      }
    case temasTypes.GUARDAR_TEMA:
      return{
          ...state,
          temas:action.payload.temas
      }
      case temasTypes.ACTUALIZAR_TEMA_EXITO:
        return {
            ...state,
            temas: state.temas.map(tema => 
                tema.id_tema === action.payload.tema.id_tema ? action.payload.tema : tema // Actualiza el tema modificado
            )
        };
      case temasTypes.ELIMINAR_TEMA_EXITO:
        return {
            ...state,
            temas: state.temas.filter(tema => tema.id_tema !== action.payload.id_tema) // Filtra el tema a eliminar
        };
    default:
      return state;
  }
}