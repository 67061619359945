import { combineReducers } from "redux";
import CursosReducer from './CursosReducer';
import ProfesoresReducer from './ProfesoresReducer';
import EstudiantesReducer from './EstudiantesReducer';
import RecursosReducer from './RecursosReducer'
import NotasReducer from './NotasReducer'
import pagosReducer from './PagosReducer';
import MensajesReducer from './MensajesReducer'
import UserReducer from './UserReducer'
import ExamenReducer from './ExamenReducer'
import AsistenciasReducer from './AsistenciasReducer';
import empresaReducer from './empresaReducer'
import tareasReducer from "./tareasReducer";
import reportesReducer from "./reportesReducer";
import tutoresReducer from "./tutoresReducer";
import encuestasReducer from "./encuestasReducer";
import apuntesReducer from "./apuntesReducer";
import controlCapacitacionesReducer from "./controlCapacitacionesReducer";
import modalReducer from "./modalReducer";
import lightBoxReducer from "./lightBoxReducer";
import trabajadoresReducer from "./trabajadoresReducer";
import programacionesReducer from "./programacionesReducer";
import finanzasReportesReducer from "./finanzasReportesReducer";
import modulosReducer from "./modulosReducer";
import matriculasReducer from './matriculasReducer';
import reporteNaranjaReducer from './reporteNaranjaReducer';
import popupReducer from './popupsReducer'
import calificacionesReducer from "./calificacionesReducer";
import indicadoresReducer from "./indicadoresReducer";
import ComunicadosReducer from "./ComunicadosReducer";
import ComentariosReducer from "./respuestasComentariosReducer";
import LeadsReducer from './LeadsReducer';
import CampaignsReducer from "./CampaignsReducer";
import foroReducers from "./foroReducers";
import BoletasReducer from "./BoletasReducer";
import BasesCertReducer from "./BasesCertReducer";
import GruposAcademicosReducer from "./GruposAcademicosReducer";
import premiosReducer from "./premiosReducer";

export default combineReducers({
  cursos: CursosReducer,
  modulos: modulosReducer,
  matriculas: matriculasReducer,
  profesores: ProfesoresReducer,
  estudiantes: EstudiantesReducer,
  recursos: RecursosReducer,
  tareas: tareasReducer,
  notas: NotasReducer,
  pagos: pagosReducer,
  finanzasReportes: finanzasReportesReducer,
  reporteNaranja: reporteNaranjaReducer,
  reporteCalificacion: calificacionesReducer,
  reporteIndicadoresPosVenta: indicadoresReducer,
  popUp: popupReducer,
  mensajes: MensajesReducer,
  usuario: UserReducer,
  tutores: tutoresReducer,
  encuestas: encuestasReducer,
  examenes: ExamenReducer,
  comunicados: ComunicadosReducer,
  asistencias: AsistenciasReducer,
  empresa: empresaReducer,
  reporte: reportesReducer,
  apuntes: apuntesReducer,
  premios: premiosReducer,
  control_capacitacion: controlCapacitacionesReducer,
  modal: modalReducer,
  lightBox: lightBoxReducer,
  trabajadores: trabajadoresReducer,
  programaciones: programacionesReducer,
  comentarios: ComentariosReducer,
  leads: LeadsReducer,
  campaigns: CampaignsReducer,
  foro: foroReducers,
  boletas: BoletasReducer,
  bases: BasesCertReducer,
  gruposAcademicos:GruposAcademicosReducer,
});