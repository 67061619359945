import React,{useEffect} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { romanize } from '../../../../helpers/functions';
import './PensionesItem.scss';
import { agregarPlan } from '../../../../reducers/CursosReducer';

const PensionesItem = ({ id_dt_plan:id, item, monto, index, nombreConcepto, fecha_limite: fechaLimite, handleEliminarCuota,id_tp_concepto,cantidad_mensualidades,monto_matricula,monto_certificado,checkboxes,editarPlan }) => {
  const planesRedux = useSelector(state => state.cursos.planes);
  const dispatch = useDispatch();
  const plan= {
    id: item,
    lapso: nombreConcepto + (index !== null ? ' ' + romanize(index+1) : ''),
    monto,
    fecha_limite: fechaLimite,
    id_tp_concepto
  };
 

  useEffect(() => {
    dispatch(agregarPlan(plan));
  }, [item, index, monto, nombreConcepto, fechaLimite, id_tp_concepto,cantidad_mensualidades,monto_matricula,monto_certificado,checkboxes]);
  

  return (
    <tr>
      <td>{item+1}</td>
      <td>{nombreConcepto} { index !== null && romanize(index+1)} </td>
      <td>S/{monto}</td>
      <td align='center'>{
          fechaLimite ? fechaLimite : 'No hay datos'
        }</td>
      {editarPlan ?
      <td align='center'>{
        handleEliminarCuota && <Button
          variant='danger'
          size='xs'
          onClick={() => handleEliminarCuota(id)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      }</td>
      : null}
    </tr>
  );
}
 
export default PensionesItem;