import React, { useRef, useState, useEffect } from "react";
import Paginacion from "./Paginacion";
import ExportExcel from "./ExportExcel";
import { buscarId, buscarClass } from "../../../../helpers/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

//Redux
import { connect,useDispatch } from 'react-redux'
import LimitadorFilas from "./LimitadorFilas";
import Search from "./Search";
import { CircularProgress } from "@material-ui/core";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import { Edit } from "@material-ui/icons";
import ModalBasicwithRedux from "../../../../containers/ModalBasicwithRedux";
import { updateStateModalBasic } from "../../../../actions/modalActions";
import EditInedirank from "./EditInedirank";


const TableReport = (props) => {

  const { rows, limitador, verNotasSesiones } = props

  const [filas, setFilas] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [currentRows, setCurrentRows] = useState([])

  const buscarRef = useRef()
  const dispatch = useDispatch()
  //console.log(rows)

  const buscar = (value = '') => {

    let copiaFilas = [...rows]

    if (rows.length === 0) {
      return
    }

    if (value === '') {
      setFilas(rows)
      return
    }


    for (let index = 1; index <= value.length; index++) {
      let acumuladorFilas = []
      copiaFilas.forEach(row => {
        let contadorFila = 0
        Object.keys(row).map(key => {
          if (row[key] !== undefined && row[key] !== null) {
            if (row[key].toString().substr(0, index).toLocaleLowerCase() == value.substr(0, index).toLocaleLowerCase()) {
              if (contadorFila < 1) {
                acumuladorFilas = [...acumuladorFilas, row]
              }
              contadorFila++;
            }
          }
        })
        contadorFila = 0
      })
      setFilas(acumuladorFilas)
    }

  }

  const ordenarColumnas = e => {

    const listaSortable = Array.from(buscarClass('sortable'))
    const head = e.target
    const atributo = e.target.id
    let orden = ''

    listaSortable.forEach(item => {
      if (item !== head) {
        if (item.classList.contains('asc')) {
          item.classList.remove('asc')
        }
        if (item.classList.contains('desc')) {
          item.classList.remove('desc')
        }
      }

    })


    if (head.classList.contains('asc')) {
      if (head.classList.contains('desc')) {
        head.classList.add('asc')
        orden = 'asc'
      } else {
        head.classList.remove('asc')
        head.classList.add('desc')
        orden = 'desc'
      }
    } else {
      if (head.classList.contains('desc')) {
        head.classList.remove('desc')
        orden = ''
      } else {
        head.classList.add('asc')
        orden = 'asc'
      }
    }


    let copiaRows = [...filas]

    switch (orden) {
      case 'asc':
        copiaRows = copiaRows.sort((prev, next) => {
          console.log(prev[atributo], next[atributo])
          return ((prev[atributo]).toString()).localeCompare((next[atributo]).toString())
        })

        setFilas(copiaRows)

        break;

      case 'desc':
        copiaRows = copiaRows.sort((prev, next) => {
          return ((next[atributo]).toString()).localeCompare((prev[atributo]).toString())
        })

        setFilas(copiaRows)
        break;
      default:
        setFilas([...rows])
    }


  }
  const generarReporte = () => {
    const btnGeneraExcel = buscarId('test-table-xls-button')

    btnGeneraExcel.click()
  }

  const updateCurrentRows = () => {
    const indexOfLastPost = currentPage * limitador;
    const indexOfFirstPos = indexOfLastPost - limitador;
    let newRows = filas.slice(indexOfFirstPos, indexOfLastPost)

    setCurrentRows(newRows)
  }

   // Modal para asignar el plan
   const handleEditInediRank = (id_matricula,inedirank) => {
    dispatch(updateStateModalBasic(
      <EditInedirank
        id_matricula={id_matricula}
        inedirank={inedirank}
        updateInediRank={updateInediRank} // Pasa el callback
      />,
      'Actualizar INEDIRANK'
    ));
  };

  const updateInediRank = (id_matricula, newInedirank) => {
    // Clonar y actualizar `rows` para encontrar el registro que necesita actualización
    const updatedRows = filas.map((fila) =>
      fila.id_matricula_proyecto === id_matricula ? { ...fila, inedirank: newInedirank } : fila
    );
    
    // Actualiza `filas` con los datos modificados para reflejar en la tabla
    setFilas(updatedRows);
  };

  useEffect(() => {
    setFilas(rows)
    if (buscarRef.current.value !== '') {
      buscar(buscarRef.current.value)
    }
    let listaAsc = document.querySelector('.sortable.asc')
    let listaDesc = document.querySelector('.sortable.desc')

    if (listaAsc) {
      ordenarColumnas({ target: listaAsc })
    }
    if (listaDesc) {
      ordenarColumnas({ target: listaDesc })
    }
  }, [rows])

  useEffect(() => {
    updateCurrentRows()
  }, [filas, currentPage, limitador])

  const paginate = pageNumber => setCurrentPage(pageNumber)

  return (
    <div className="table-report table-hover px-3">

      <div className="menu">
        <div className="row">
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-6">

                <LimitadorFilas />
              </div>
              <div className="col-md-6">

              </div>
            </div>

          </div>
          <div className="col-md-8">
            <div className="content-button w-100 d-flex justify-content-end">

              <Search
                buscar={buscar}
                buscarRef={buscarRef}
              />
              <button
                type='button'
                className='btn btn-primary btn-sm'
                onClick={() => generarReporte()}
              >
                <i className='fas fa-file-excel'></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <table id="table" className="table-responsive">
          <thead >
            <tr>
              <th scope="col" colSpan="3" className='px-5'>
                SEMÁFORO
              </th>
              <th scope="col" colSpan="5">
                DATOS
              </th>
              <th scope="col" className="economico agrandar">
                ESTADO ECONÓMICO
              </th>
              <th scope="col" className="academico agrandar" colSpan="6">
                ESTADO ACADÉMICO
              </th>
              <th scope="col" rowSpan="2" className="px-3">
                PROGRESO
              </th>
              <th scope="col" rowSpan="2" className="px-3">
                ACCIONES
              </th>
              <th scope="col" rowSpan="2" className='px-5'>
                FECHA
              </th>
              <th scope="col" rowSpan="2" className='px-5'>
                HORA
              </th>

              <th scope="col" rowSpan="2" className="px-5">
                OBSERVACIONES
              </th>

              <th scope="col" rowSpan="2" className="px-5">
                Puntos INEDI TOTALES
              </th>
            </tr>
            <tr>
              <th scope="col" className="bg-success"><span style={{ display: 'block', width: '50px', height: '40px' }} onClick={() => { buscar('VERDE') }}></span></th>
              <th scope="col" className="bg-warning"><span style={{ display: 'block', width: '50px', height: '40px' }} onClick={() => { buscar('AMBAR') }}></span></th>
              <th scope="col" className="bg-danger"><span style={{ display: 'block', width: '50px', height: '40px' }} onClick={() => { buscar('ROJO') }}></span></th>

              <th scope="col">PRODUCTO</th>
              <th className='discente sortable' id='nombre' scope="col" onClick={e => ordenarColumnas(e)}>NOMBRE</th>
              <th className='discente sortable' id='apellido' scope="col" onClick={e => ordenarColumnas(e)}>APELLIDO</th>
              <th scope="col">CORREO</th>
              <th scope="col">TELÉFONO</th>

              <th scope="col" className='sortable' id='importe_deuda' onClick={e => ordenarColumnas(e)}>DEUDA (S/.)</th>

              <th scope="col">TTAL. SESIONES</th>
              <th scope="col">SESIONES REALIZADAS</th>
              <th scope="col">SESIONES ASISTIDAS</th>
              <th scope='col'>N. ÓPTIMA</th>
              <th scope="col" className='sortable nota' id='promedio_final' onClick={e => ordenarColumnas(e)}>NOTA</th>
              <th scope='col'>Puntos INEDI</th>
            </tr>
          </thead>

          <tbody>
            {
              currentRows.length ?
                currentRows.map((fila, index) => (
                  <tr key={index}>
                    <td
                      colSpan="3"
                      className='td-semaforo'
                    >
                      <div
                        style={{
                          background: `${fila.color_estado === 'AMBAR' ? (
                              '#F39C12'
                            ) : (
                              fila.color_estado === 'VERDE' ? (
                                '#18BB60'
                              ) : (
                                '#E12E1C'
                              )
                            )
                            }`
                        }}
                        className='semaforo'
                      >
                        {fila.color_estado}
                      </div>
                    </td>
                    <td>{fila.proyecto}</td>
                    <td>{fila.nombre}</td>
                    <td>{fila.apellido}</td>
                    <td>{fila.correo}</td>
                    <td>{fila.telefono}</td>

                    <td className='text-center'>
                      {fila.importe_deuda}
                    </td>

                    <td className='text-center'>
                      {fila.cant_total_sesiones}
                    </td>
                    <td className='text-center'>
                      {fila.cant_se_realizada}
                    </td>
                    <td className='text-center'>
                      {fila.cant_asistidas}
                    </td>
                    <td className='text-center'>
                      {fila.promedio_optimo}
                    </td>
                    <td className='text-center nota'>
                      <a
                        href="#"
                        title='detallar'
                        className='nota__valor'
                        onClick={e => { e.preventDefault(); verNotasSesiones(fila.id_matricula_proyecto) }}
                      >
                        {fila.promedio_final}
                      </a>
                    </td>
                    <td>
                      {fila.puntos_adicionales}
                    </td>
                    <td className='text-center'>
                      <CircularProgressWithLabel value={fila.progreso} />
                    </td>
                    <td>
                      {fila.button}
                    </td>
                    <td className='text-center'>
                      {fila.fecha_edicion}
                    </td>
                    <td className='text-center'>
                      {fila.hora_edicion}
                    </td>

                    <td>
                      <select name="observaciones" disabled value={fila.observaciones || ''} className="form-control">
                        <option value=''>--seleccionar--</option>
                        <option value='cancela_deuda'>Cancelara deuda</option>
                        <option value='activo'>Estará activo</option>
                        <option value='justificar_asistencia'>Justificará asistencia</option>
                        <option value="participa_clase">Participará mas en clases</option>
                        <option value="rendira_evaluacion">Rendirá evaluación extemporánea</option>
                      </select>
                    </td>
                    <td className='text-center'>
                      
                          {fila.inedirank}
                          <button onClick={()=>handleEditInediRank(fila.id_matricula_proyecto,fila.inedirank)} className="ml-3 btn btn-info">
                            <FontAwesomeIcon icon={faEdit} />
                          </button>
 
                    
                    </td>
                  </tr>

                )) : (
                  <tr>
                    <td colSpan='14'>
                      <div className=' text-center my-3'>
                        No hay Filas
                      </div>
                    </td>
                  </tr>
                )
            }


          </tbody>
        </table>
        <div className="footer">


          <Paginacion
            totalRows={rows.length}
            paginate={paginate}

          />
        </div>
      </div>
      <ExportExcel />
      <ModalBasicwithRedux />
    </div>
  );
};

const mapStateToProps = state => ({
  limitador: state.reporte.limitador
})

export default connect(mapStateToProps, {})(TableReport);
