import {
  OBTENIENDO_TUTORES,
  TUTORES_OBTENIDOS,
  ERROR_OBTENER_TUTORES,
  GUARDAR_TUTORES_SELECT,
  OBTENIENDO_EVENTOS_TUTOR,
  EVENTOS_TUTOR_OBTENIDO,
  ERROR_OBTENIENDO_EVENTOS,
  ELIMINAR_EVENTO_TUTOR_ERROR,
  ELIMINAR_EVENTO_TUTOR,
  ELIMINAR_EVENTO_TUTOR_EXITO,
  ELIMINAR_TUTOR
} from "./types";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import authAxios from '../config/authAxios';
import { avisoCargando, avisoCorrecto, avisoError } from "../view/components/AvisosMensajes/MensajesSwalf";


const error = type => {
  return {
    type
  };
};

const guardarTutores = tutores => {
  return {
    type: TUTORES_OBTENIDOS,
    payload: tutores
  };
};


export const eliminarTutor = idUsuario => {
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
      });
      swalWithBootstrapButtons
      .fire({
          title: 'Estas seguro?',
          text: "Se ocultará el registro y de necesitarlo de nuevo deberá solicitarlo al área TI",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async function() {
          //consultando para ver el mensaje de aprovación
          try {
              await authAxios.delete(`/admin/us_desactivar_tutor/${idUsuario}`)
              dispatch({
                  payload: idUsuario,
                  type: ELIMINAR_TUTOR
              })
              return true;
          } catch (error) {
              Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: error.response.data.msg
              })
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
          //mensaje que se cambio la contraseña satisfactoriamente
          if (result.value === true) {
          swalWithBootstrapButtons.fire(
              "Eliminado!",
              "Trabajador se elimino correctamente",
              "success"
          );
          }
      });
  }
}


const guardarSelectTutores = tutores => {
  
  let selectTutores = new Array;

  [...tutores].map(tutor => {
    selectTutores = [...selectTutores, {
      value: tutor.id_tutor,
      label: `${tutor.nombre_usuario} ${tutor.apellido_usuario}`,
      ...tutor
    }]
  })

  return {
    type: GUARDAR_TUTORES_SELECT,
    payload: selectTutores
  }
}

const loading = type => {
  return {
    type
  };
};

const guardarEventosTutor = eventos => {
  return {
    type: EVENTOS_TUTOR_OBTENIDO,
    payload: eventos
  }
}



export const obtenerTutores = () => {
  return async (dispatch) => {
    dispatch( loading(OBTENIENDO_TUTORES) )
    try {
      const tutores = await authAxios.get(`/admin/us_mostrarUsuarioTutor`)

      dispatch( guardarTutores(tutores.data) )
    } catch (error) {
      console.log(error)
      dispatch( error(ERROR_OBTENER_TUTORES) )
    }
  };
};

export const obtenerTutoresSelect = () => {
  return async (dispatch) => {
    dispatch( loading(OBTENIENDO_TUTORES) )
    try {
      const tutores = await authAxios.get(`/admin/us_mostrarUsuarioTutor`)

      dispatch( guardarSelectTutores(tutores.data) )
    } catch (error) {
      console.log(error)
      dispatch( error(ERROR_OBTENER_TUTORES) )
    }
  };
}

export const guardarAsignación = dataAsignacion => {
  return async dispatch => {
    avisoCargando('Guardando...')

    try {
      const respuesta = await authAxios.post(`/admin/us_asignarUsuarioTutor`, dataAsignacion)
      
      if (!respuesta.data.error) {
        avisoCorrecto('Tutor asigado a nuevo evento')
        
        return
      }

      avisoError(respuesta.data.error)
    } catch (error) {
      
    }
  }
}

export const obtenerEventosTutor = idEmpleado => {
  return async dispatch => {
    dispatch( loading(OBTENIENDO_EVENTOS_TUTOR) )

    try {
 
      const eventos = await authAxios.get(`/admin/us_mostrarDetallesEventosTutor/${idEmpleado}`)

      if (!eventos.data.error) {
        dispatch( guardarEventosTutor(eventos.data) )
      }else {
        avisoError(eventos.data.error)
      }

    } catch (error) {
      console.log(error)
      dispatch( error(ERROR_OBTENIENDO_EVENTOS) )
    }
  }
}

const eliminarEventoExito = eventos => {
  return {
    type: ELIMINAR_EVENTO_TUTOR_EXITO,
    payload: eventos
  }
} 

export const eliminarEventosTutor = (idEventoTutor) => {
  return async dispatch => {
    dispatch( loading(ELIMINAR_EVENTO_TUTOR) )

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons
      .fire({
        title: 'Estas seguro?',
        text: 'Se eliminara la relación del evento con el tutor',
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, continuar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async function() {

          try {
            const respuesta = await authAxios.delete(`/admin/rptet_eliminarTutorProyecto/${idEventoTutor}`)
          
            //consultando para ver el mensaje de aprovación
            if(!respuesta.error) {
              dispatch( eliminarEventoExito(respuesta.data) )
              return {
                resultado: true,
                textResultado: 'Eliminado correctamente'
              }
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: respuesta.date.error
              });
            }
          } catch (error) {
            dispatch( error(ELIMINAR_EVENTO_TUTOR_ERROR) )
            console.log(error.response)
          }
          
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
        //mensaje que se cambio la contraseña satisfactoriamente
        const {resultado, textResultado} = result.value || {}

        if (resultado === true) {
          swalWithBootstrapButtons.fire(
            'Eliminado',
            textResultado,
            "success"
          );
        }
      });

    }
}