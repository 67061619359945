import { error } from 'jquery';
import {
    premiosTypes
} from '../actions/types';


const initialState = {
    premios: [],
    loading: false,
    error: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case premiosTypes.LISTAR_PREMIOS:
            return {
                ...state,
                loading: true,
            };
        case premiosTypes.LISTAR_PREMIOS_EXITO:
            return {
                ...state,
                loading: false,
                premios: action.payload,
            };
        case premiosTypes.LISTAR_PREMIOS_ERROR:
            return {
                ...state,
                loading: false,
                error : action.payload
            };
        default:
            return state;
    }
};

