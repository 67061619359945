import React,{useState} from 'react';
import { Modal, Button, Form, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { actualizarInediRank } from '../../../../actions/reportesActions';
import { updateStateModalBasic } from '../../../../actions/modalActions';

const EditInedirank = ({ id_matricula, inedirank,updateInediRank }) => {
    const dispatch = useDispatch();
    const [rank, setRank] = useState(inedirank);



    const handleSave = (e) => {
        e.preventDefault();
        // Aquí puedes agregar la lógica para guardar el número de matrícula
        dispatch(actualizarInediRank(rank, id_matricula)).then(() => {
            updateInediRank(id_matricula, rank);
            dispatch(updateStateModalBasic());
        });
    };

    return (
        <div className="container p-4">
            <Form onSubmit={handleSave}>
                <Form.Row>
                    <Form.Group as={Col} md={6}>
                        <Form.Label>Puntos totales INEDIRANK</Form.Label>
                        <Form.Control
                            type="text"
                            value={rank}
                            onChange={(e) => setRank(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={6} className="text-left">
                        <Button
                            className="btn-spinner"
                            variant="info"
                            type="submit"
                        >
                            Guardar
                        </Button>
                    </Form.Group>
                </Form.Row>
            </Form>
        </div>
    );
};


export default EditInedirank;