import React, { useState, useEffect } from "react";
import HeaderContent from "../../../General/HeaderContent";
import Breadcrumb from "../../../General/Breadcrumb";
import Layout from "../../../../../app/Layout";
import SimpleSelect from "../../../../components/plugins/SimpleSelect";
import { useParams } from "react-router-dom";
import ListaEncuestas from "../../../../components/ListaEncuestas";
import { Container } from "react-bootstrap";
import ModalBasic from "../../../../components/Modals/ModalBasic";
//Redux
import { connect } from "react-redux";
import { obtenerEncuestasTutor } from "../../../../../actions/encuestasAction";
import { listarSesionesCursos } from "../../../../../actions/cursosActions";
import CompartirEncuesta from "../../../../components/CreacionEncuesta/CompartirEncuesta/CompartirEncuesta";
import MensajeLoading from "../../../../components/MensajeLoading";
import SpinnerLoading from "../../../../components/Spinners/SpinnerLoading";

const Encuestas = (props) => {
  const {
    selectSesiones,
    loading,
    listarSesionesCursos,
    obtenerEncuestasTutor,
  } = props;

  const params = useParams();
  const { idEvento, idEventoTutor } = params;

  const [sesion, setSesion] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [consultaEncuestas, setConsultaEncuestas] = useState(false);
  const [encuesta, setEncuesta] = useState({})

  const updateShowModal = (valor) => {
    setShowModal(valor);
  };

  const obtenerEncuestas = () => {
    if (Object.keys(sesion).length > 0) {
      //Cambiar el state para que desaparesca el componente de mensaje para buscar
      setConsultaEncuestas(true);
      //ejecutar funcion de obtencion
      obtenerEncuestasTutor(sesion.value, idEventoTutor);
    }
  };

  //Funcion para guardar la encuesta que se selecciona para compartir
  const guardarEncuesta = infoEncuesta => {
    setEncuesta(infoEncuesta)
  }

  useEffect(() => {
    listarSesionesCursos(idEvento, "");
  }, []);

  useEffect(() => {
    obtenerEncuestas();
  }, [sesion]);

  return (
    <Layout>
      <HeaderContent title="Encuestas" />
      <Breadcrumb
        link={[
          { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
          { url: "/tutor/eventos", nombre: "Eventos" },
          { url: "", nombre: "Encuestas" },
        ]}
      />
      <div className="encuestas">
        <ModalBasic
          show={showModal}
          title="Compartir"
          updateShowModal={updateShowModal}
        >
          <CompartirEncuesta
            updateShowModal={updateShowModal}
            encuesta={encuesta}
          />
        </ModalBasic>
        <div className="container-fluid px-0 content-actions my-3">
          <div className="row encuetas__content-filtro">
            <div className="col-md-4 filtro">
              <label htmlFor="">Sesiones</label>
              <SimpleSelect options={selectSesiones} getSelection={setSesion}
                menuPortalTarget={document.body} // Hace que el menú se renderice fuera del contenedor
              />
            </div>
          </div>
        </div>
        {!consultaEncuestas ? (
          <MensajeLoading
          // imagen='/images/encuesta_busqueda.jpg'
          />
        ) : (
          <Container>
            <SpinnerLoading loading={loading}>
              <div className="p-0 mt-4">
                <ListaEncuestas
                  updateShowModal={updateShowModal}
                  idEvento={idEvento}
                  idEventoTutor={idEventoTutor}
                  guardarEncuesta={guardarEncuesta}
                />
              </div>
            </SpinnerLoading>
          </Container>
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  selectSesiones: state.cursos.selectSesiones,
  loading: state.encuestas.loading,
});

const mapDispatchToProps = {
  listarSesionesCursos,
  obtenerEncuestasTutor,
};

export default connect(mapStateToProps, mapDispatchToProps)(Encuestas);
