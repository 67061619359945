import React, { useEffect,useState } from 'react';
import { Button, Form, FormControl, InputGroup, Modal, Spinner } from 'react-bootstrap';
import { faCalendar, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import * as yup from 'yup';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { updateStateModalBasic } from '../../../../actions/modalActions';
import { obtenerConceptosPagos, registrarPagoExtraordinario } from '../../../../actions/pagosActions';
import Fatal from '../../MessageAlert/Fatal';

const FormNuevoPagoExtra = ({ idMatricula }) => {

  const dispatch = useDispatch();
  const { loading, error, datos } = useSelector(state => state.pagos.conceptosPagos);
  const [isMontoEditable, setIsMontoEditable] = useState(true);

  const initialState = {
    "id_matricula": idMatricula,
    "id_tp_concepto_pago":"",
    "monto_concepto":"",
    "fecha_limite":""
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: yup.object({
      id_tp_concepto_pago: yup.number()
        .typeError('Entrada no válida.')
        .positive()
        .required('El concepto de pago es obligatorio'),
      monto_concepto: yup.number()
        .typeError('Entrada no válida: Número por favor')
        .positive('El número tiene que ser positivo')
        .required('El monto es obligatorio'),
      fecha_limite: yup.date()
        .typeError('Entrada no válida: Fecha en formato dd-mm-yyyy')
        .required('La fecha limite es obligatorio')
    }),
    onSubmit: valores => {
      dispatch(registrarPagoExtraordinario(valores));
    }
  })

  const handleModal = () => {
    dispatch(updateStateModalBasic());
  }

  const handleConceptoChange = (e) => {
    const selectedConceptoId = parseInt(e.target.value);
    formik.setFieldValue('id_tp_concepto_pago', selectedConceptoId);

    if (selectedConceptoId === 6) {
      setIsMontoEditable(true);
      formik.setFieldValue('monto_concepto', ""); // Permitimos que el usuario ingrese un monto
    } else if (selectedConceptoId === 7) {
      setIsMontoEditable(false);
      formik.setFieldValue('monto_concepto', 200); // Monto fijo de 200
    } else if (selectedConceptoId === 8) {
      setIsMontoEditable(false);
      formik.setFieldValue('monto_concepto', 350); // Monto fijo de 350
    } else {
      setIsMontoEditable(true); // Otros conceptos pueden ser editables
      formik.setFieldValue('monto_concepto', "");
    }
  };

  useEffect(() => {
    if (!datos) {
      dispatch(obtenerConceptosPagos());
    }
  }, [])

  if(loading) return <Modal.Body className='text-center'>
    <Spinner as='span' animation='border' />
  </Modal.Body>
  if(error) return <Modal.Body>
    <Fatal message={error} />
  </Modal.Body>

  return ( 
    <div className="FormNuevoPagoExtra">
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
        <Form.Group>
          <Form.Label htmlFor='id_tp_concepto_pago'>Concepto de pago</Form.Label>
          <Form.Control
            as='select'
            id='id_tp_concepto_pago'
            name='id_tp_concepto_pago'
            value={formik.values.id_tp_concepto_pago}
            onChange={handleConceptoChange}
            isInvalid={formik.errors.id_tp_concepto_pago}
          >
            <option value="" disabled>--Selección--</option>
            {
              datos && datos.extraordinario.map(concepto => (
                <OptionConceptos
                  key={concepto.id_tp_concepto_pago}
                  {...concepto}
                />
              ))
            }
          </Form.Control>
          <Form.Control.Feedback type='invalid'>
            {formik.errors.id_tp_concepto_pago}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor='monto_concepto'>Total a pagar</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                S/.
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl 
              type='text'
              id='monto_concepto'
              name='monto_concepto'
              value={formik.values.monto_concepto}
              onChange={formik.handleChange}
              isInvalid={formik.errors.monto_concepto}
              readOnly={!isMontoEditable}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.monto_concepto}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor='fecha_limite'>Fecha límite</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faCalendar} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl 
              type='date'
              id='fecha_limite'
              name='fecha_limite'
              value={formik.values.fecha_limite}
              onChange={formik.handleChange}
              isInvalid={formik.errors.fecha_limite}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.fecha_limite}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Form.Group className='text-right'>
            <Button className='mr-2 btn-icon' variant='dark' onClick={handleModal}>
              <FontAwesomeIcon icon={faTrash} />
              Cancelar
            </Button>
            <Button type='submit' variant='info' className='btn-icon'>
              <FontAwesomeIcon icon={faPlus} />
              Agregar
            </Button>
          </Form.Group>
        </Modal.Footer>
      </Form>
    </div>
  );
}
 
export default FormNuevoPagoExtra;

const OptionConceptos = props => {

  const { id_tp_concepto_pago: idConcepto, concepto_pago: concepto} = props;

  return (
    <option value={idConcepto}>{concepto}</option>
  )
}