import {
    premiosTypes
} from './types'


import Swal from 'sweetalert2'
import authAxios from '../config/authAxios'
import { avisoCargando, avisoCorrecto, avisoError } from '../view/components/AvisosMensajes/MensajesSwalf'


export const listarPremiosRuleta = () => async dispatch => {
    try {
        dispatch({ type: premiosTypes.LISTAR_PREMIOS, })

        const detallePremios = await authAxios.get(`/admin/listarPremiosRuleta`)
        dispatch({
            type: premiosTypes.LISTAR_PREMIOS_EXITO,
            payload: detallePremios.data.data
        })

    } catch (error) {
        console.log(error)
        dispatch({
            type: premiosTypes.LISTAR_PREMIOS_ERROR,
            payload: error
        })
        avisoError(error)
    }

}

export const actualizarEstadoRuleta = (id,caso) => async dispatch => {
    const data = {id,caso}
    try {
        const rpta = await authAxios.put(`/admin/actualizarEstadoRuleta`,data)
        console.log(rpta)
        avisoCorrecto(rpta.data.estado);
    } catch (error) {
        console.log(error)
        avisoError(error.response.data.error)
    }
}