import React, { useEffect, useState } from 'react';
import Layout from '../../../app/Layout';
import { Container, Row, Col, Form, Button } from "react-bootstrap";

import Breadcrumb from '../General/Breadcrumb';
import HeaderContent from '../General/HeaderContent';
import SpinnerLoading from '../../components/Spinners/SpinnerLoading'
import { crearEnlace } from '../../../actions/comercialActions';
import { avisoCorrecto, avisoError } from '../../components/AvisosMensajes/MensajesSwalf';
import { useDispatch, useSelector } from 'react-redux';
import { obtenerProyectosActivos } from '../../../actions/cursosActions';

const CrearEnlacesFormularios = () => {
  const [link, setLink] = useState('');
  const { usuario } = useSelector(state => state.usuario)
  const [productos, setProductos] = useState([]);
  const [productoSeleccionado, setProductoSeleccionado] = useState(null);
  const [errorProducto, setErrorProducto] = useState(false); // Estado para el mensaje de error

  const dispatch = useDispatch();
  console.log(usuario)
  console.log(productoSeleccionado)
  console.log(productos)
  const fetchData = async () => {
    if (!productoSeleccionado) {
      setErrorProducto(true); // Mostrar error si no se selecciona un producto
      return;
    }
    const respuesta = await dispatch(crearEnlace(usuario.id_usuario, productoSeleccionado));
    if (respuesta.mensaje && usuario.id_usuario) {

      avisoCorrecto("Link creado con éxito")
      setLink(respuesta.url)
    }
    else {
      avisoError("Hubo un error al crear el link.")
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const respuesta = await dispatch(obtenerProyectosActivos());
      if (respuesta) {
        setProductos(respuesta);
      }
    };
    fetchData();
  }, [dispatch]);


  const handleProductoChange = (event) => {
    setProductoSeleccionado(event.target.value); // Actualiza el producto seleccionado
    if (event.target.value) {
      setErrorProducto(false); // Limpiar el error si se selecciona un producto
    }
  };


  return (
    <Layout>
      <Container className="ControlReportes">
        <div className="ControlReportes__header">
          <HeaderContent title="Generar enlace de Contrato" />
          <Breadcrumb
            link={[
              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
              { url: "/contratos/enlaces", nombre: "Enlaces" },
            ]}
          />
        </div>
        <div className="ControlReportes__body">
          <div className="box box-primary">
            <div className="box-body">
              <Row className="mb-3">
                <Col md={4}>
                  <Form.Group controlId="producto">
                    <Form.Label>Seleccionar Producto:</Form.Label>
                    <Form.Control
                      as="select"
                      value={productoSeleccionado}
                      onChange={handleProductoChange}
                      style={{ maxWidth: "100%", width: "100%" }} // Asegura que el select se mantenga dentro de su contenedor
                    >
                      <option value="">Seleccione un producto</option>
                      {productos.map((proyecto) => (
                        <option key={proyecto.id_proyecto} value={proyecto.id_proyecto}>
                          {proyecto.nombre_proyecto}
                        </option>
                      ))}
                    </Form.Control>
                    {errorProducto && (
                      <Form.Text style={{ color: "red", fontSize: "13px", fontWeight: "bold" }}>
                        Por favor, seleccione un producto.
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Enlace:</Form.Label>
                    <Form.Control type="text" value={link} readOnly />
                  </Form.Group>
                </Col>
                <Col md={2} className="d-flex align-items-end justify-content-center">
                  <Button className="mt-2" onClick={fetchData}>
                    Crear Enlace
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );

}

export default CrearEnlacesFormularios;