import React from "react";
import { Table, Alert, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import * as moment from 'moment';
//redux
import { connect } from 'react-redux';

import "./ListaReportesTutor.scss";

const COLUMS_TABLA = [
  { label: "Sesión"},
  { label: "Nombre" },
  { label: "Fecha" },
  { label: "Estado" },
  { label: "Reporte" }
];

const Columna = ({ label, onlyClase }) => {
  return (
    <>
      <th className={onlyClase}>{label}</th>
    </>
  );
};

const FilaTablas = props => {

  const { index, reporte } = props

  return (
    <tr>
      <td align='center' className={`fila fila-item"`}>{reporte.id_sesion}</td>
      <td align='center' className={`fila fila-nombre`}>{reporte.nombre_sesion}</td>
      <td align='center' className={`fila fila-fecha`}>{reporte.fecha ? moment(reporte.fecha).format('DD MMM YYYY, h:mm:ss a') : '---'}</td>
      <td align='center'>
        <Badge variant={`${reporte.id_tp_estado_formulario === '2' ? 'success' : 'warning'}`}>
          {reporte.id_tp_estado_formulario === '2' ? 'Completo' : 'Pendiente'}
        </Badge>
      </td>
      <td className={`fila fila-test`}>
        <Link
          to={`/tutor/eventos/encuestas/${reporte.id_tutor_formulario}`}
          className='btn btn-success btn-test'
        >
          <FontAwesomeIcon icon={faArrowAltCircleRight} />
          Reporte
        </Link>
      </td>
    </tr>
  );
};

const ListaReportesTutor = props => {

  const { reportesTutor } = props

  return (
    <div className="lista-sesiones">
      <div className="lista-sesiones__main-tabla">
        <Table responsive="md">
          <thead className="header-tabla">
            <tr>
              {COLUMS_TABLA.map((column, index) => (
                <Columna key={index} {...column} />
              ))}
            </tr>
          </thead>
          <tbody className="body-tabla">
            { reportesTutor.length > 0 ? (
              reportesTutor.map((reporte, index) => (
                <FilaTablas
                  key={reporte.id_tutor_formulario}
                  reporte={reporte} 
                  index={index}
                />
              ))
            ) : (
              <Alert variant='warning'>
                No hay encuestas
              </Alert>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  reportesTutor: state.encuestas.reportesTutor
})

export default connect(mapStateToProps, {})(ListaReportesTutor);
