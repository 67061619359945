import authAxios from '../config/authAxios';
import { avisoCorrecto, avisoError } from '../view/components/AvisosMensajes/MensajesSwalf';
import { updateStateModalBasic, updateStateModalCustom } from './modalActions'



export const crearEnlace = (id_usuario,id_producto) => async dispatch => {
  
    try {
      let respuesta = await authAxios.get(`/admin/crearCodigoFormulario_/${id_usuario}/${id_producto}`)
  
      if (!respuesta.data.error) 
      {
        return {mensaje: true, url: respuesta.data.url}
      }
      else
        {   
          return {mensaje: false, textMensaje: respuesta.data.error}
        }
      
    } catch (error) {
    //  console.log(error)
      return {mensaje: false, textMensaje: error}
    }
    
  }

  
export const obtenerContratos = (data) => async dispatch => {
  try {
    const{
      id_usuario: idusuario,
      id_tp_nivel_usuario: nivelusuario
      } = data
    let respuesta = await authAxios.get(`/admin/obtenerContratos/${idusuario}/${nivelusuario}`)
    if (!respuesta.data.error) 
    {
      return {mensaje: true, contratos: respuesta.data}
    }
    else
      {   
        return {mensaje: false, contratos: null}
      }
    
  } catch (error) {
   // console.log(error)
    return {mensaje: false, textMensaje: error}
  }
}

export const asignarPlan = (plan) => async dispatch =>{
  try{
    let respuesta = await authAxios.post(`/admin/asignarPlan`,plan)
    avisoCorrecto("Se asigno el plan correctamente");
    dispatch(updateStateModalBasic());

  }catch(error){
    console.log(error)
    avisoError("No se pudo asignar el plan")
    return {mensaje: false, textMensaje: error}
  }
}
  
export const obtenerTopVentas = (consulta) => async dispatch => {
  try {
    let respuesta = await authAxios.post(`/admin/obtenerTopVentas`,consulta)
    //console.log(respuesta)
    if (!respuesta.data.error) 
    {
      return {mensaje: true, top: respuesta.data}
    }
    else
      {   
        return {mensaje: false, top: null}
      }
    
  } catch (error) {
    //console.log(error)
    return {mensaje: false, textMensaje: error}
  }
}
  