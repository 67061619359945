import React from 'react'
import HeaderContent from '../General/HeaderContent';
import Breadcrumb from '../General/Breadcrumb';
import MenuMensajeria from './componentesMensajeria/MenuMensajeria';
import { useState } from 'react';
import BandejaEntrada from './componentesMensajeria/BandejaEntrada';
import Enviados from './componentesMensajeria/Enviados';
import CrearMensaje from './componentesMensajeria/CrearMensaje';
import './Mensajeria.scss';
import Swal from 'sweetalert2'
//REDUX
import {connect, useDispatch} from 'react-redux'
import {
  listarBandeja,
  listarMensajesEnviados,
  listarContactos,
  mandarMensajeBandejaPapelera,
  mandarMensajeEnviadosPapelera,
  eliminarMensaje,
  listarPapelera
} from '../../../actions/mensajesActions'
import { useEffect } from 'react';
import Layout from '../../../app/Layout';
import { avisoError } from '../../components/AvisosMensajes/MensajesSwalf';
import Papelera from './componentesMensajeria/Papelera';
import { ACTUALIZAR_TAB_MENSAJERIA } from '../../../actions/types';

const Mensajeria = (props) => {
    //State
  const {usuario, currentPanel,hilo} = props
    //Funciones
  const { listarBandeja,
    listarMensajesEnviados,
    listarContactos,
    mandarMensajeEnviadosPapelera,
    mandarMensajeBandejaPapelera,
    eliminarMensaje,
    listarPapelera
  } = props;


    const [vista, setVista] = useState('bandejaEntrada')
    const [mensajesSeleccionados, setMensajesSeleccionados] = useState([])
    const dispatch=useDispatch();

   // funcion para cambiar los paneles
    const handlePanel = (p) =>{
      dispatch({
        type:ACTUALIZAR_TAB_MENSAJERIA,
        payload:p
      })
    }
  
    //Funcion para guardar lo mensajes seleccionados
  const guardarMensajesSeleccionados = (mensaje) => {
   // console.log(mensaje)
    //Si el checked es true se guarda
    if (mensaje.checked) {
      setMensajesSeleccionados([...mensajesSeleccionados, {id_mensaje: mensaje.id}])
      return
    }
    //Si no es true se pasa a eliminar
    let nuevaSeleccion = mensajesSeleccionados.filter(m => m.id_mensaje !== mensaje.id)
    setMensajesSeleccionados(nuevaSeleccion)
  }

    //Mover a la papelera los mensaje
  const moverMensajePapelera = () => {

    //VALIDAR si NO selecciono nada
    if (mensajesSeleccionados.length === 0) {
      avisoError('No hay selecciones')
      return
    }


    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: '¿Estas seguro?',
      text: "El mensaje se movera a la papelera",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, continuar!',
      cancelButtonText: 'No, cancelar!',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: async function () {
        //consultando para ver el mensaje de aprovación
        if (vista === 'bandejaEntrada') {
          if((await mandarMensajeBandejaPapelera(usuario.id_usuario, mensajesSeleccionados)).mensaje){
            setMensajesSeleccionados([])
            return true
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Error en eliminar los mensajes"
            });
          }
        }
        if (vista === 'enviados') {
          //Ejecuat funcion
          if((await mandarMensajeEnviadosPapelera(usuario.id_usuario, mensajesSeleccionados)).mensaje){
            setMensajesSeleccionados([])
            return true
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Error en eliminar los mensajes"
            });
          }
        }
        
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      //mensaje que se cambio la contraseña satisfactoriamente
      if (result.value === true) {
        swalWithBootstrapButtons.fire(
          'Movido!',
          'Se movio a la papelera',
          'success'
        )
      }
    })

  }

  //Eliminar completamente un mensaje
  const eliminarMensajeCompleto = () => {
    
    if (mensajesSeleccionados.length === 0) {
      avisoError('No se selecciono mensajes')
      return
    }

    let data = {
      id_usuario: usuario.id_usuario,
      eliminados: mensajesSeleccionados
    }
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "El mensaje se movera a la papelera",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, continuar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async function() {
          //consultando para ver el mensaje de aprovación
            if ((await eliminarMensaje(data)).mensaje) {
              setMensajesSeleccionados([])
              return true;
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Error en eliminar los mensajes"
              });
            
          }
          
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
        //mensaje que se cambio la contraseña satisfactoriamente
        if (result.value === true) {
          swalWithBootstrapButtons.fire(
            "Eliminado!",
            "Se elimino con exito",
            "success"
          );
        }
      });
    
    
  }

    useEffect(() => {
        listarBandeja(usuario.id_usuario,usuario.id_tp_nivel_usuario)
        listarMensajesEnviados(usuario.id_usuario)
        listarPapelera(usuario.id_usuario)
      listarContactos(0,0)
    }, [usuario])

    useEffect(() => {
      /*LISTAR LA BANDEJAS */
      if (currentPanel) {
        setVista(currentPanel)
      }
    }, [currentPanel]);
  
  

    return (
      <Layout>
        <HeaderContent title="Mensajes" icon="mail_outline" />
        <Breadcrumb
              link={[
                {url:'/inicio', nombre: 'Inicio', icon: 'fas fa-home'},
                { url: "", nombre: "Gestión del Servicio" },
               { url: "", nombre: "Mensajes" },
              ]}
          />
        <div className="container-fluid mensajeria mt-4">
          <div className="row">
            <div className="col-md-2 px-0">
              <MenuMensajeria
                setVista={handlePanel}
                vista={vista}
                setMensajesSeleccionados={setMensajesSeleccionados}
              />
            </div>
            <div className="col-md-10">
              {vista === "responder" ? (
                <CrearMensaje
                  guardarMensajesSeleccionados={guardarMensajesSeleccionados}
                  hilo={hilo}
                />
              ) : vista === "bandejaEntrada" ? (
                <BandejaEntrada
                  guardarMensajesSeleccionados={guardarMensajesSeleccionados}
                  moverMensajePapelera={moverMensajePapelera}
                />
              ) : vista === "enviados" ? (
                <Enviados
                  guardarMensajesSeleccionados={guardarMensajesSeleccionados}
                  moverMensajePapelera={moverMensajePapelera}
                />
              ) : vista === "nuevoMensaje" ? (
                <CrearMensaje
                  guardarMensajesSeleccionados={guardarMensajesSeleccionados}
                />
              ) : vista === 'papelera' ? 
                <Papelera
                  guardarMensajesSeleccionados={guardarMensajesSeleccionados}
                  eliminarMensajeCompleto={eliminarMensajeCompleto}
                />
              : null}
            </div>
          </div>
        </div>
      </Layout>
    );
}

const mapStateToProps = state => ({
    bandejaMensaje: state.mensajes.bandejaMensaje,
    currentPanel: state.mensajes.currentTab,
    hilo: state.mensajes.hilo,
    usuario: state.usuario.usuario
})

const mapDispatchToProps = {
  mandarMensajeEnviadosPapelera,
  mandarMensajeBandejaPapelera,
  listarBandeja,
  listarMensajesEnviados,
  listarContactos,
  eliminarMensaje,
  listarPapelera
}

export default connect(mapStateToProps, mapDispatchToProps)(Mensajeria);